import { useTranslation } from "react-i18next";
import { Col, Form } from "react-bootstrap";
import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { Transportation } from "sharedTypes/modelTypes";
import { TLegFormValues } from "./TripCalculator";

import TransportationVariantSelectOptions from "./TransportationVariantSelectOptions";
import {
  getTransportationVariantInputType,
  getTransportationVariantRange,
} from "src/helpers/transportation";

// conditionally renders form inputs for different transportation variants.
// i.e Car has a range slider for passengers but Train can choose different countries
type TTransportationVariantInputProps = {
  register: UseFormRegister<TLegFormValues>;
  transportation: Transportation;
  errors: DeepMap<TLegFormValues, FieldError>;
  watch: UseFormWatch<TLegFormValues>;
};
const TransportationVariantInput: React.FC<
  TTransportationVariantInputProps
> = ({ transportation, register, errors, watch }) => {
  const selectedVariant = watch("transportationVariant");
  const transportationVariantInputType =
    getTransportationVariantInputType(transportation);

  const { t } = useTranslation("common");

  if (transportationVariantInputType === "range") {
    return (
      <Form.Group as={Col} controlId="formLegTransportationVariant">
        <Form.Label>
          {t("Anzahl Passagiere")}: {selectedVariant}
        </Form.Label>
        <Form.Control
          {...register("transportationVariant", { required: true })}
          min={getTransportationVariantRange(transportation)!.min}
          max={getTransportationVariantRange(transportation)!.max}
          defaultValue={getTransportationVariantRange(transportation)!.min}
          custom
          type="range"></Form.Control>
        <p>{errors.transportationVariant && t("Variante wählen")}</p>
      </Form.Group>
    );
  } else if (transportationVariantInputType === "select") {
    return (
      <Form.Group as={Col} controlId="formLegTransportationVariant">
        <Form.Label>{t("Variante")}</Form.Label>
        <div className="select-wrapper">
          <Form.Control
            as="select"
            {...register("transportationVariant", { required: true })}>
            <TransportationVariantSelectOptions
              transportation={transportation}
            />
          </Form.Control>
        </div>
        <p>{errors.transportationVariant && t("Variante wählen")}</p>
      </Form.Group>
    );
  } else {
    // render nothing
    return null;
  }
};

export default TransportationVariantInput;
