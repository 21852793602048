import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useFind, useMutation } from "figbird";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";

import QRCode from "qrcode.react";
import NewClassForm from "./NewClassForm";
import ClassesList from "./ClassesList";
import { Class } from "sharedTypes/modelTypes";
import { TLogin } from "src/@types";

// component to display all available classes that is the ones the current user owns
type TClassesProps = {
  login: TLogin;
};
const Classes: React.FC<TClassesProps> = ({ login }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalQrText, setModalQrText] = useState("");

  // all classes
  const { data: classList, isFetching } = useFind<Class>("api/classes", {
    query: {
      userId: login.user.id,
    },
    skip: !login,
  });

  const { create: createClass } = useMutation<Class>("api/classes");
  // wrap createClass to add userId
  const createClassWithUserId = (classData: Partial<Class>) =>
    createClass({ ...classData, userId: login.user.id });
  const { t } = useTranslation("common");

  if (!login) return null;

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <h4>{t("Ihre Klassen Titel")}</h4>
          {isFetching && <Spinner animation="border" variant="info" />}
          <ClassesList
            login={login}
            classes={classList}
            setShowModal={setShowModal}
            setModalQrText={setModalQrText}></ClassesList>
        </Col>
        <Col xs={12} md={6}>
          <h4>{t("Neue Klasse erstellen")}</h4>
          <NewClassForm onSubmit={createClassWithUserId}></NewClassForm>
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header closeButton>{t("EinladungslinkQR")}</Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <ResponsiveEmbed>
            <QRCode renderAs="svg" value={modalQrText} />
          </ResponsiveEmbed>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Classes;
