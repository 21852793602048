import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";

const HomePage: React.FC = () => {
  const { t } = useTranslation("Startseite");

  return (
    <>
      <h1 className="h2">{t("Titel")}</h1>
      <p>{t("Intro1")}</p>
      <p>{t("Intro2")}</p>
      <h2 className="h3">{t("ReiseBerechnenTitel")}</h2>
      <div className="info-box">
        {t("ReiseBerechnenText")}
        <br></br>
        <LinkContainer to="/calculate">
          <a href="/calculate" className="btn btn-primary mt-4">
            {t("ReiseBerechnenButton")}
          </a>
        </LinkContainer>
      </div>
      <h2 className="h3 mt-5">{t("Subtitel2")}</h2>
      <div className="d-flex flex-column flex-md-row">
        <div className="info-box" style={{ flex: 1 }}>
          <h3 className="h4">{t("KlasseBeitretenTitel")}</h3>
          {t("KlasseBeitretenText")}
          <br></br>
          <LinkContainer to="/classes/join">
            <a href="/classes/join" className="btn btn-primary mt-4">
              {t("KlasseBeitretenButton")}
            </a>
          </LinkContainer>
        </div>
        <div className="info-box ml-md-4 mt-4 mt-md-0" style={{ flex: 1 }}>
          <h3 className="h4">{t("KontoAnlegenTitel")}</h3>
          {t("KontoAnlegenText")}
          <LinkContainer to="/login">
            <a href="/login" className="btn btn-secondary mt-4">
              {t("KontoAnlegenButton")}
            </a>
          </LinkContainer>
        </div>
      </div>
    </>
  );
};

export default HomePage;
