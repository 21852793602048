import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import "@myclimate/styles";
import "./custom.scss";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

import translations_de from "./assets/i18n/de.json";
import translations_en from "./assets/i18n/en.json";
import translations_fr from "./assets/i18n/fr.json";
import translations_it from "./assets/i18n/it.json";

const translations = {
  de: translations_de,
  en: translations_en,
  fr: translations_fr,
  it: translations_it,
};

const language =
  sessionStorage.getItem("language") ||
  localStorage.getItem("language") ||
  window.navigator.language?.substring(0, 2) ||
  "de";

i18next.use(initReactI18next).init({
  lng: language,
  fallbackLng: "de",
  supportedLngs: ["de", "fr", "it", "en"],
  nonExplicitSupportedLngs: false,
  debug: window.location.hostname === "localhost",
  interpolation: { escapeValue: false },
  defaultNS: "common",
  resources: translations,
  saveMissing: true,
  // When using language 'cimode', this shows the full path for the keys
  appendNamespaceToCIMode: true,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
