import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useFind, useGet } from "figbird";
import { paramsForServer } from "feathers-hooks-common";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ClassScatterPlot from "./ClassScatterPlot";

import { useState } from "react";

import TripConcise from "./TripConcise";
import Header from "./Header";
import { TLogin } from "src/@types";
import { Class, Leg, UserWithPin } from "sharedTypes/modelTypes";
import UserPins from "./UserPins";
import EmissionPerTransportWaffle from "../analysis/EmissionPerTransportWaffle";
import TransportationColorLegend from "../analysis/TransportationColorLegend";

// display charts etc. for a specific class
type TClassProps = {
  login: TLogin | null;
};
const ClassView: React.FC<TClassProps> = ({ login }) => {
  const history = useHistory();
  const { classId } = useParams<{ classId: string }>();
  // get class
  const { data: classInfo } = useGet<Class>("api/classes", classId);

  const { t } = useTranslation("Klasseseite");

  const { data: classMembers } = useFind<UserWithPin>(
    "api/users",
    Object.assign(
      paramsForServer({
        query: { classId: classId },
        include: "userpin",
      }),
      { realtime: "refetch" }
    )
  );

  const [selectedStudentId, setSelectedStudentId] = useState<number | null>(
    null
  );
  const toggleSelectedStudent = (userId: number) => {
    if (selectedStudentId === userId) {
      setSelectedStudentId(null);
    } else {
      setSelectedStudentId(userId);
    }
  };

  const [activeStudentIds, setActiveStudentIds] = useState<number[]>([]);
  const filterActiveLegs = (legs: Leg[]) =>
    legs.filter((leg) => activeStudentIds.includes(leg.userId));
  const filterActiveUsers = (classMembers: UserWithPin[]) =>
    classMembers.filter((user) => activeStudentIds.includes(user.id));
  const toggleActiveUser = (userId: number) => {
    if (activeStudentIds.includes(userId)) {
      setActiveStudentIds(
        activeStudentIds.filter((studentId) => userId !== studentId)
      );
      if (selectedStudentId === userId) {
        setSelectedStudentId(null);
      }
    } else {
      setActiveStudentIds(activeStudentIds.concat([userId]));
    }
  };
  // and their trips
  const { data: classLegs } = useFind<Leg>("api/legs", {
    query: { classId: classId },
    realtime: "refetch",
    // don't run query if classMembers are still null (i.e while they're being fetched)
    skip: !classMembers,
  });

  const [lastStudentIds, setLastStudentIds] = useState<number[]>([]);

  if (!login) {
    history.push("/");
    return null;
  } else if (classInfo && classMembers && classLegs) {
    if (!activeStudentIds) {
      setActiveStudentIds(classMembers.map((user) => user.id));
    } else {
      // classMembers were updated. Select new students automatically
      const newMemberIds = classMembers.map((user) => user.id);
      const newStudentIds = newMemberIds.filter(
        (user) => !lastStudentIds.includes(user)
      );

      if (newStudentIds.length > 0) {
        setActiveStudentIds(activeStudentIds.concat(newStudentIds));
        setLastStudentIds(newMemberIds);
      }
    }

    return (
      <>
        <div>
          <Header title={classInfo.name} />
          <Row className="justify-content-md-center">
            <Col>
              <ClassScatterPlot
                legs={filterActiveLegs(classLegs)}
                users={filterActiveUsers(classMembers)}
                selectedStudentId={selectedStudentId}
                toggleSelectedStudent={toggleSelectedStudent}
              />
            </Col>
          </Row>
          <div className="info-box mt-4">
            <h2 className="h3">{t("EmissionenTransportmittel")}</h2>
            <EmissionPerTransportWaffle
              legs={filterActiveLegs(classLegs)}
              defaultWaffleSizeKg={100}
            />
            <div className="mt-4">
              <TransportationColorLegend />
            </div>
          </div>
          <div className="info-box mt-4">
            <h2 className="h3">{t("ReisenTitel")}</h2>
            <p>{t("ReisenIntro")}</p>
            <>
              {classMembers.map(({ id, name, destination }) => {
                return (
                  <TripConcise
                    key={id}
                    name={name || "-unknown-"}
                    destination={destination || ""}
                    legs={classLegs.filter((leg) => leg.userId === id)}
                    onClick={() => toggleActiveUser(id)}
                    active={activeStudentIds.includes(id)}
                  />
                );
              })}
            </>
          </div>
        </div>
        {!login.user.classId && (
          <div className="info-box mt-4">
            <h2 className="h3">{t("KlassenmitgliederTitel")}</h2>
            <p>{t("KlassenmitgliederIntro")}</p>
            <UserPins users={classMembers} />
          </div>
        )}
      </>
    );
  } else {
    return <p>{t("Message")}</p>;
  }
};

export default ClassView;
