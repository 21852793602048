import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-bootstrap";

const Header: React.FC<{ title: string }> = ({ title }) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const { t } = useTranslation("headerComponent");

  return (
    <>
      <h1 className="h2">{title}</h1>

      <a
        className="d-flex link-collapse"
        data-toggle="collapse"
        href="#collapsable"
        role="button"
        aria-expanded={isInfoOpen ? "true" : "false"}
        onClick={() => setIsInfoOpen(!isInfoOpen)}>
        <i
          className={isInfoOpen ? "icn-pfeil-s-rechts" : "icn-pfeil-s-unten"}
          style={{ width: "2.5rem" }}></i>
        <span>{t("common:Informationen")}</span>
      </a>
      <Collapse in={isInfoOpen}>
        <div className="collapse">
          <div style={{ marginLeft: "2.5rem" }}>
            <p dangerouslySetInnerHTML={{ __html: t("Intro") }} />
            <ul className="mt-2">
              <li dangerouslySetInnerHTML={{ __html: t("Line1") }} />
              <li dangerouslySetInnerHTML={{ __html: t("Line2") }} />
            </ul>
            <p dangerouslySetInnerHTML={{ __html: t("Outro") }} />
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default Header;
