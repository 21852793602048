import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { Transportation } from "sharedTypes/modelTypes";
import {
  getTransportationLabel,
  transportationColors,
} from "src/helpers/transportation";

const TransportationColorLegend: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <Row>
      <Col style={{ display: "flex", flexWrap: "wrap" }}>
        {Object.entries(transportationColors).map(([transportation, color]) => (
          <div
            key={transportation}
            style={{ display: "flex", marginRight: "0.5rem" }}>
            <span
              style={{
                width: "20px",
                height: "20px",
                display: "block",
                backgroundColor: color,
                marginRight: "0.2rem",
              }}></span>
            <span>
              {getTransportationLabel(t, transportation as Transportation)}
            </span>
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default TransportationColorLegend;
