import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TLogin } from "src/@types";

type TNavigationForTeachersProps = {
  login: TLogin;
  logout: () => void;
};
const NavigationForTeachers: React.FC<TNavigationForTeachersProps> = ({
  login,
  logout,
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <li className="nav-item">
        <Link to="/" className="nav-link">
          {t("FurLehrer")}
        </Link>
        <ul className="lvl2 navlist">
          <li className="last nav-item">
            <Link to="/classes" className="nav-link">
              {t("MeineKlassen")}
            </Link>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <Button
          variant="outline-secondary"
          onClick={logout}
          className="nav-link mt-4">
          {t("Abmelden")}
        </Button>
      </li>
    </>
  );
};
export default NavigationForTeachers;
