import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap"; // Make sure to import the necessary components from react-bootstrap

const StepButton: React.FC<{
  onStepClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled: boolean;
  buttonText: string;
}> = ({ onStepClick, isDisabled, buttonText }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onStepClick(e);
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={0} sm={0} md={8} lg={8} xl={8}></Col>{" "}
        {/* Create an empty column to push the button to the right */}
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={{ span: 4, offset: 8 }}
          xl={{ span: 4, offset: 8 }}
          className="mt-3">
          <Button
            variant="primary"
            type="submit"
            onClick={handleClick}
            disabled={isDisabled}>
            {buttonText}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default StepButton;
