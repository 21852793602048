import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
type TFormValues = {
  name: string;
};
type TNewClassFormProps = {
  onSubmit: SubmitHandler<TFormValues>;
};
const NewClassForm: React.FC<TNewClassFormProps> = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm<TFormValues>();
  const { t } = useTranslation("common");
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formBasicName">
        <Form.Label>{t("Name der Klasse")}</Form.Label>
        <Form.Control
          {...register("name", { required: true })}
          type="text"
          placeholder={t("KlassePlacholder")}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        {t("Erstellen")}
      </Button>
    </Form>
  );
};

export default NewClassForm;
