import { useTranslation } from "react-i18next";
import { Leg } from "sharedTypes/modelTypes";
import { Col, Row } from "react-bootstrap";

import DistancePerTransportPie from "./DistancePerTransportPie";
import EmissionPerTransportWaffle from "./EmissionPerTransportWaffle";
import TransportationColorLegend from "./TransportationColorLegend";

type TAnalysisTripProps = {
  legs: Leg[];
  defaultWaffleSizeKg?: number;
};
const AnalysisTrip: React.FC<TAnalysisTripProps> = ({
  legs,
  defaultWaffleSizeKg,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className="info-box">
      <p dangerouslySetInnerHTML={{ __html: t("tripViewSolo:AnalyseIntro") }} />
      <Row>
        {/* need a fixed height for Responsive nivo Charts */}
        <Col xs={12} lg={6}>
          <b>{t("DistanzProTransportmittel")}</b>
          <div className="mt-4" style={{ height: "40vh" }}>
            <DistancePerTransportPie legs={legs} />
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-4 mt-lg-0">
          <b>{t("EmissionenProTransportmittel")}</b>
          <EmissionPerTransportWaffle legs={legs} defaultWaffleSizeKg={10} />
        </Col>
      </Row>
      <div className="mt-4">
        <TransportationColorLegend />
      </div>
    </div>
  );
};

export default AnalysisTrip;
