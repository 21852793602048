import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Spinner, Container } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Provider } from "figbird";

import HomePage from "src/components/HomePage";
import TripViewSolo from "src/components//TripViewSolo";
import TripViewStudent from "src/components//TripViewStudent";

import client from "src/helpers/feathers";
import { TLogin } from "src/@types";
import Header from "./header/Header";
import Login from "./Login";
import Classes from "./classes/Classes";
import ClassJoinView from "./classes/ClassJoinView";
import ClassView from "./class/ClassView";
import { ErrorBoundary } from "@appsignal/react";
import Appsignal from "@appsignal/javascript";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";
import { plugin as pathDecoratorPlugin } from "@appsignal/plugin-path-decorator";

const appsignal = new Appsignal({
  key:
    window.location.hostname ===
    "shapeyourtrip.myc-staging.org"
      ? "7657ea7f-3b6a-4340-b98a-cbb1b1cfee6d"
      : "00273742-5c5a-40ea-90eb-7d60cccab3d8",
  revision: process.env.REACT_APP_APPSIGNAL_REVISION,
});
appsignal.use(windowEventsPlugin());
appsignal.use(pathDecoratorPlugin());

const App: React.FC = () => {
  const [login, setLogin] = useState<TLogin | null>(null);
  const [isFetching, setIsFetching] = useState(true);

  // once component did mount
  useEffect(() => {
    // Try to authenticate with the JWT stored in localStorage
    const authenticateWithJWT = async () => {
      try {
        await (client as any).authenticate();
        console.log("logged in via localStorage JWT");
      } catch (error) {
        setLogin(null);
      }
      setIsFetching(false);
    };

    // Set up event listeners
    const handleAuthenticated = (login: TLogin) => {
      setLogin(login);
      setIsFetching(false);
    };

    const handleLogout = () => {
      setLogin(null);
      setIsFetching(false);
    };

    // Set up event listeners on component mount
    (client as any).on("authenticated", handleAuthenticated);
    (client as any).on("logout", handleLogout);

    // Try to authenticate with JWT and clean up event listeners on component unmount
    authenticateWithJWT();
    return () => {
      (client as any).off("authenticated", handleAuthenticated);
      (client as any).off("logout", handleLogout);
    };
  }, []);

  const { t } = useTranslation("common");

  if (isFetching) {
    return <Spinner animation="border" data-testid="spinner" />;
  } else {
    return (
      <ErrorBoundary instance={appsignal}>
        <Provider feathers={client}>
          <Router>
            <Header login={login} />
            <main className="page-content">
              <Container>
                <Row>
                  <Col>
                    <Switch>
                      <Route exact path="/trip">
                        {login === null ? (
                          <p>{t("mustConnect")}</p>
                        ) : (
                          <TripViewStudent login={login} />
                        )}
                      </Route>
                      <Route exact path="/login">
                        <Login login={login} setLogin={setLogin}></Login>
                      </Route>
                      <Route path="/classes/join">
                        <ClassJoinView setLogin={setLogin}></ClassJoinView>
                      </Route>
                      <Route exact path="/classes">
                        {login && <Classes login={login}></Classes>}
                      </Route>
                      <Route path="/class/:classId">
                        <ClassView login={login} />
                      </Route>
                      <Route path="/calculate">
                        <TripViewSolo></TripViewSolo>
                      </Route>
                      <Route path="/">
                        <HomePage />
                      </Route>
                    </Switch>
                  </Col>
                </Row>
              </Container>
            </main>
          </Router>
        </Provider>
      </ErrorBoundary>
    );
  }
};

export default App;
