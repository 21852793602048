import { useTranslation } from "react-i18next";
import { ResponsiveWaffle } from "@nivo/waffle";
import {
  addEmissions,
  groupByAndSum,
  nextPerfectSquare,
  totalEmissions,
} from "src/helpers/data";
import {
  getTransportationLabel,
  transportationColors,
} from "src/helpers/transportation";
import { useForm } from "react-hook-form";
import { Leg, Transportation } from "sharedTypes/modelTypes";
import { useEffect, useState, useMemo } from "react";

type TEmissionPerTransportWaffleProps = {
  legs: Leg[];
  waffleSizeKg?: number;
  defaultWaffleSizeKg?: number;
};

const EmissionPerTransportWaffle: React.FC<
  TEmissionPerTransportWaffleProps
> = ({ legs, defaultWaffleSizeKg = 100 }) => {
  const { register, watch, setValue } = useForm<{ waffleKgPerTile: number }>();

  // Initialize watchwaffleKgPerTile based on the selected waffleKgPerTile
  const watchwaffleKgPerTile = watch("waffleKgPerTile", defaultWaffleSizeKg);

  useEffect(() => {
    // Update the watch value when the default value changes
    setValue("waffleKgPerTile", defaultWaffleSizeKg);
  }, [defaultWaffleSizeKg, setValue]);

  const [, setWaffleKgPerTile] = useState(defaultWaffleSizeKg * 1000);
  useEffect(() => {
    // When the defaultWaffleSizeKg prop changes, update the state
    setWaffleKgPerTile(defaultWaffleSizeKg * 1000);
  }, [defaultWaffleSizeKg]);

  const legsWithEmissions = addEmissions(legs);
  const emissionPerTransport = groupByAndSum(
    legsWithEmissions,
    (x) => x.leg.transportation,
    (x) => x.emissions_in_kgCO2
  );

  const data = useMemo(
    () =>
      Object.keys(emissionPerTransport).map((key) => ({
        id: key,
        label: key,
        value: emissionPerTransport[key],
        color: transportationColors[key as Transportation],
      })),
    [emissionPerTransport]
  );

  const totalKgCO2 = totalEmissions(legs);

  const numberOfTiles = (tileSizeInKilos: number) =>
    Math.ceil(totalKgCO2 / tileSizeInKilos);

  // total tiles taken for emissions
  let tilesForEmissions = numberOfTiles(watchwaffleKgPerTile);

  let actualKgCO2PerTile = watchwaffleKgPerTile;

  // Limit number of tiles for performance reasons
  const MAX_TILES = 1024;
  const tilesExceededLimit = tilesForEmissions > MAX_TILES;

  if (tilesExceededLimit) {
    tilesForEmissions = MAX_TILES;
    actualKgCO2PerTile = totalKgCO2 / tilesForEmissions;
  } else {
    actualKgCO2PerTile = watchwaffleKgPerTile;
  }

  // dimensions for the waffle
  const tilesTotal = nextPerfectSquare(tilesForEmissions);
  const tilesPerAxis = Math.sqrt(tilesTotal);
  const gCO2ForAllTiles = tilesTotal * actualKgCO2PerTile;
  const { t } = useTranslation("common");

  return data.length ? (
    <>
      <div
        style={{
          fontSize: "1rem",
          color: "black",
          fontWeight: "bold",
          marginTop: "1rem",
        }}>
        {t("Total")}: {totalKgCO2.toFixed(3)} {t("Kg")} CO<sub>2</sub>
      </div>
      <div
        className="mt-4"
        style={{
          height: "40vh",
          marginBottom: "0.5rem",
        }}>
        <ResponsiveWaffle
          data={data}
          total={gCO2ForAllTiles}
          rows={tilesPerAxis}
          columns={tilesPerAxis}
          padding={1}
          emptyOpacity={0}
          colors={{ datum: "color" } as any} // as any due to bug in nivo types
          tooltip={({ id, value }) => (
            <>
              <b>{getTransportationLabel(t, id as Transportation)}</b>
              <br />
              {value.toFixed(3)} {t("Kg")} CO
              <sub>2</sub>
            </>
          )}
          animate={false}
        />
      </div>
      <div className="mt-2" style={{ marginBottom: "0.5rem" }}>
        <small className="text-muted">
          {t("tripViewSolo:BeschreibungIntro")}{" "}
          {tilesExceededLimit ? (
            <b>
              {(actualKgCO2PerTile / 1000).toFixed(3)} {t("Kg")}
            </b>
          ) : (
            <select
              {...register("waffleKgPerTile")}
              value={
                watchwaffleKgPerTile?.toString() ??
                defaultWaffleSizeKg?.toString() ??
                "100"
              }>
              {numberOfTiles(100) > MAX_TILES ? null : (
                <option value="100">100 {t("Kg")}</option>
              )}
              {numberOfTiles(10) > MAX_TILES ? null : (
                <option value="10">10 {t("Kg")}</option>
              )}
              {numberOfTiles(1000) > MAX_TILES ? null : (
                <option value="1000">1 t</option>
              )}
            </select>
          )}{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("tripViewSolo:BeschreibungIntro2"),
            }}
          />
        </small>
      </div>
    </>
  ) : (
    <></>
  );
};

export default EmissionPerTransportWaffle;
