import { useTranslation } from "react-i18next";
import { Accordion, Badge, Button, Card, Col, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useFind } from "figbird";
import { Class, User } from "sharedTypes/modelTypes";
import { useState } from "react";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TLogin } from "src/@types";

type TClassDetailProps = {
  clazz: Class;
  login: TLogin | null;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalQrText: React.Dispatch<React.SetStateAction<string>>;
};
const ClassDetail: React.FC<TClassDetailProps> = ({
  clazz,
  login,
  setShowModal,
  setModalQrText,
}) => {
  const { data: members } = useFind<User>("api/users", {
    query: {
      classId: clazz.id,
    },
    skip: !login,
  });
  const inviteLink =
    window.location.origin + "/classes/join?q=" + clazz.inviteCode;

  const [inviteCodeCopied, setInviteCodeCopied] = useState(false);
  const [inviteLinkCopied, setInviteLinkCopied] = useState(false);

  const { t } = useTranslation("common");

  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="secondary"
          eventKey={`${clazz.id}`}>
          {clazz.name + " "}
          {members && <Badge variant="light">{members.length}</Badge>}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={`${clazz.id}`}>
        <Card.Body>
          <>
            <Row>
              <Col md={6}>
                <div>
                  {t("Einladungscode")}{" "}
                  <b style={{ fontSize: "2.5rem" }}>{clazz.inviteCode}</b>
                  <div>
                    <CopyToClipboard
                      text={clazz.inviteCode}
                      onCopy={() => {
                        setInviteCodeCopied(true);
                        setTimeout(() => setInviteCodeCopied(false), 2500);
                      }}>
                      <Button variant="link">{t("Kopieren")}</Button>
                    </CopyToClipboard>
                    {inviteCodeCopied && (
                      <span style={{ marginLeft: "0.5rem" }}>
                        <FaCheck color="#b6cb82" />
                      </span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <QRCode
                  onClick={() => {
                    setModalQrText(inviteLink);
                    setShowModal(true);
                  }}
                  renderAs="svg"
                  value={inviteLink}
                />
              </Col>
            </Row>
            <div>{t("Einladungslink")}</div>
            <LinkContainer to={"/classes/join?q=" + clazz.inviteCode}>
              <a href={inviteLink}>{inviteLink}</a>
            </LinkContainer>
            <div>
              <CopyToClipboard
                text={inviteLink}
                onCopy={() => {
                  setInviteLinkCopied(true);
                  setTimeout(() => setInviteLinkCopied(false), 2500);
                }}>
                <Button variant="link">{t("Kopieren")}</Button>
              </CopyToClipboard>
              {inviteLinkCopied && (
                <span style={{ marginLeft: "0.5rem" }}>
                  <FaCheck color="#b6cb82" />
                </span>
              )}
            </div>
          </>
          <LinkContainer to={`class/${clazz.id}`}>
            <Button>{t("Klasse öffnen")}</Button>
          </LinkContainer>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default ClassDetail;
