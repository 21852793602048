import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Leg,
  Transportation,
  TransportationVariant,
} from "sharedTypes/modelTypes";

interface RowOriginal {
  leg: {
    name: string;
    distanceInKm: number;
    transportation: string;
    transportationVariant: string;
    origin: string;
  };
  emissions_in_kgCO2: number;
}
interface TransportDropdownProps {
  row: RowOriginal;
  onSelectChange: (selectedOption: string, newAdditionalValue: string) => void;
  handleDistanceRequest: (
    leg: Leg,
    mode: string,
    transitMode?: string
  ) => Promise<any>;
  calculateFlyingDistance: (leg: Leg) => number;
  calculateSailingDistance: (leg: Leg) => number;
  noDistanceCalculation: (leg: Leg) => number;
  currentTransportation: Transportation;
  currentTransportationVariant: TransportationVariant;
}

interface TransportConfig {
  [key: string]: {
    label: string;
    additionalInput?: {
      type: "range" | "select";
      options?: string[] | { [key: string]: string };
      max?: number;
    };
  };
}

const TransportDropdown: React.FC<TransportDropdownProps> = ({
  row,
  onSelectChange,
  currentTransportation,
  currentTransportationVariant,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    currentTransportation ? currentTransportation : "" // Use the prop as the default value
  );

  const [additionalInputValue, setAdditionalInputValue] = useState<
    string | number
  >(
    currentTransportationVariant !== undefined
      ? currentTransportationVariant
      : row.leg.transportation &&
        ["car", "eCar", "motorcycle", "miniBus"].includes(
          row.leg.transportation
        )
      ? 1
      : ""
  );

  useEffect(() => {
    setSelectedOption(currentTransportation ? currentTransportation : ""); // Update selectedOption when the prop changes
  }, [currentTransportation]);

  useEffect(() => {
    setAdditionalInputValue(
      currentTransportationVariant !== undefined
        ? currentTransportationVariant
        : row.leg.transportation &&
          ["car", "eCar", "motorcycle", "miniBus"].includes(
            row.leg.transportation
          )
        ? 1
        : ""
    ); // Update additionalInputValue when the prop changes
  }, [currentTransportationVariant, row.leg.transportation]);

  const handleTransportChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedOption = e.target.value;

    const TransportsWithSliderAsAdditionalOption = [
      "car",
      "eCar",
      "miniBus",
      "motorcycle",
    ];

    const newAdditionalInputValue =
      TransportsWithSliderAsAdditionalOption.includes(newSelectedOption)
        ? 1
        : "";

    // Update the state and call onSelectChange
    setAdditionalInputValue(newAdditionalInputValue);
    setSelectedOption(newSelectedOption);
    onSelectChange(newSelectedOption, String(newAdditionalInputValue));
  };

  const handleAdditionalInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const newAdditionalValue = e.target.value;
    setAdditionalInputValue(newAdditionalValue);
    onSelectChange(selectedOption, newAdditionalValue);
  };

  const { t } = useTranslation("common");

  const transportConfig: TransportConfig = {
    car: {
      label: t("Auto"),
      additionalInput: { type: "range", max: 5 },
    },
    walk: { label: t("zuFuss") },
    bike: {
      label: t("Fahrrad"),
      additionalInput: {
        type: "select",
        options: {
          [t("KonventionellesFahrrad")]: "bike",
          [t("E-Bike")]: "eBike",
        },
      },
    },
    train: {
      label: t("Zug"),
      additionalInput: {
        type: "select",
        options: {
          [t("Deutschland")]: "germany",
          [t("Frankreich")]: "france",
          [t("Italien")]: "italy",
          [t("Österreich")]: "austria",
          [t("Schweiz")]: "switzerland",
          [t("Schweden")]: "sweden",
          [t("Norwegen")]: "norway",
          [t("Grossbritanien")]: "greatBritain",
          [t("Slowenien")]: "slovenia",
          [t("Kroatien")]: "croatia",
          [t("Europa")]: "europe",
        },
      },
    },
    miniBus: {
      label: t("Minibus"),
      additionalInput: { type: "range", max: 13 },
    },
    travelBus: { label: t("Reisebus") },
    plane: {
      label: t("Flugzeug"),
      additionalInput: {
        type: "select",
        options: {
          [t("FlugInnerhalbEuropa")]: "shortDistance",
          [t("FlugInterkontinental")]: "longDistance",
        },
      },
    },
    ship: { label: t("Schiff") },
    eCar: {
      label: t("Elektroauto"),
      additionalInput: { type: "range", max: 5 },
    },
    motorcycle: {
      label: t("Motorrad"),
      additionalInput: { type: "range", max: 2 },
    },
    ferry: {
      label: t("Fähre"),
      additionalInput: {
        type: "select",
        options: {
          [t("zuFuss")]: "walking",
          [t("mitAuto")]: "driving",
        },
      },
    },
    "": {
      label: t("Transportmittel auswählen"),
    },
  };

  return (
    <>
      <div className="transport-dropdown-container">
        <select
          value={selectedOption}
          onChange={handleTransportChange}
          className="options-selection">
          {Object.keys(transportConfig).map((option) => (
            <option key={option} value={option}>
              {transportConfig[option].label}
            </option>
          ))}
        </select>
      </div>

      {transportConfig[selectedOption]?.additionalInput && (
        <div className="additional-input-container">
          {transportConfig[selectedOption]?.additionalInput?.type ===
            "range" && (
            <>
              <input
                type="range"
                min="1"
                max={transportConfig[selectedOption]?.additionalInput?.max}
                value={additionalInputValue as number}
                onChange={handleAdditionalInputChange}
                className="options-selection"
                title="Passagierzahl"
              />
              <span className="range-value">{additionalInputValue}</span>
            </>
          )}
          {transportConfig[selectedOption]?.additionalInput?.type ===
            "select" && (
            <select
              value={additionalInputValue as string}
              className="options-selection"
              onChange={handleAdditionalInputChange as any}>
              <option disabled value="">
                {`Option auswählen`}
              </option>
              {transportConfig[selectedOption]?.additionalInput?.options &&
                Object.entries(
                  transportConfig[selectedOption]?.additionalInput?.options as {
                    [key: string]: string;
                  }
                ).map(([label, value]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
            </select>
          )}
        </div>
      )}
    </>
  );
};

export default TransportDropdown;
