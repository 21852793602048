import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import client from "../helpers/feathers";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { TLogin } from "src/@types";

type TFormValues = {
  email: string;
  password: string;
};
type TLoginProps = {
  login: TLogin | null;
  setLogin: React.Dispatch<React.SetStateAction<TLogin | null>>;
};
const Login: React.FC<TLoginProps> = ({ login, setLogin }) => {
  // #TODO: share login state across application .. Context Provider?
  const [error, setError] = useState<string>();
  const history = useHistory();

  const { register, handleSubmit, getValues } = useForm<TFormValues>();

  const { t } = useTranslation("Loginseite");

  const handleLogin = (data: TFormValues) => {
    const { email, password } = data;

    return client
      .authenticate({
        strategy: "local",
        email,
        password,
      })
      .then((result) => setLogin(result as TLogin))
      .catch((error) => {
        switch (error.name) {
          case "NotAuthenticated":
            setError(t("BenutzernameOderPasswortFalsch"));
            break;
          default:
            console.error(error);
        }
      });
  };

  const signup = () => {
    const credentials = getValues();
    client
      .service("api/users")
      .create(credentials)
      .then(() => handleLogin(credentials))
      .catch((error: any) => {
        switch (error.message) {
          case "email_exists":
            setError(t("E-MailExistiertBereits"));
            break;
          default:
            console.error(error);
        }
      });

    return;
  };

  const onSubmit: SubmitHandler<TFormValues> = (credentials) => {
    handleLogin(credentials);
  };

  useEffect(() => {
    if (login) {
      if (login.user.classId) {
        history.push("/trip");
      } else {
        history.push("/classes");
      }
    }
  }, [login, history]);

  // otherwise return login form
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="h2">{t("Titel")}</h1>
      <p>{t("Intro")}</p>
      <p>
        {t("Intro2")}
        <a
          href="https://www.myclimate.org/de/datenschutz"
          target="_blank"
          rel="noopener noreferrer">
          {t("Datenschutz")}
        </a>
      </p>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{t("common:Email")}</Form.Label>
        <Form.Control {...register("email", { required: true })} type="email" />
        <Form.Text className="text-muted">{t("Keine")}</Form.Text>
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>{t("common:Passwort")}</Form.Label>
        <Form.Control
          {...register("password", { required: true })}
          type="password"
        />
      </Form.Group>

      <Row>
        <Col>
          <Button
            variant="outline-secondary"
            onClick={(e) => {
              e.preventDefault();
              signup();
            }}>
            {t("common:Registrieren")}
          </Button>
        </Col>
        <Col>
          <Button variant="primary" type="submit">
            {t("common:Einloggen")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Login;
