import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NavigationForGuests: React.FC = () => {
  const { t } = useTranslation("Startseite");
  return (
    <>
      <li className="first nav-item">
        <Link to="/" className="nav-link">
          {t("common:Informationen")}
        </Link>
        <Link to="/calculate" className="nav-link">
          {t("common:MeineReise")}
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/" className="nav-link">
          {t("KlasseBeitretenTitel")}
        </Link>
        <ul className="lvl2 navlist">
          <li className="last nav-item">
            <Link to="/classes/join" className="nav-link">
              {t("KlasseBeitretenButton")}
            </Link>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <Link to="/login" className="nav-link">
          {t("KontoAnlegenTitel")}
        </Link>
        <ul className="lvl2 navlist">
          <li className="last nav-item">
            <Link to="/login" className="nav-link">
              {t("common:LoginRegister")}
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
};

export default NavigationForGuests;
