export const extractNearestPlace = (geocoder: google.maps.Geocoder, latLng: google.maps.LatLng) => {
        return new Promise((resolve, reject) => {
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === 'OK') {
                    if (results && results[0]) {
                        const addressComponents = results[0].address_components;
    
                        let nearestPlace = '';
                        let locality = '';
                        let adminAreaLevel2 = '';
    
                        addressComponents.forEach((addressComponent => {
                            const currentType = addressComponent.types[0];
                            if (currentType === 'locality' && locality === '') {
                                locality = addressComponent.long_name;
                            } else if (
                                currentType === 'administrative_area_level_2' &&
                                adminAreaLevel2 === ''
                            ) {
                                adminAreaLevel2 = addressComponent.long_name;
                            }
                        }))
    
                        if (locality !== '') {
                            nearestPlace = locality;
                        } else if (adminAreaLevel2 !== '') {
                            nearestPlace = adminAreaLevel2;
                        } else {
                            nearestPlace = addressComponents[0].long_name;
                        }
    
                        resolve(nearestPlace);
                    } else {
                        resolve('');
                    }
                } else {
                    reject(new Error('Geocoder failed due to: ' + status));
                }
            });
        });
    };
    