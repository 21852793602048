import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Collapse from "react-bootstrap/Collapse";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import client from "../../helpers/feathers";

import { TLogin } from "src/@types";
import ZettelNavigation from "./ZettelNavigation";
import NavigationForGuests from "./NavigationForGuests";
import NavigationForTeachers from "./NavigationForTeachers";
import NavigationForStudents from "./NavigationForStudents";
import isFeatureEnabled from "../../helpers/isFeatureEnabled";

type THeaderProps = RouteComponentProps & {
  login: TLogin | null;
};
const Header: React.FC<THeaderProps> = ({ login, history }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const logout = () => {
    client.logout();
    history.push("/");
  };

  history.listen((_location, action) => {
    if (action === "PUSH") setMenuOpen(false);
  });

  const { i18n } = useTranslation();
  // Under some circumstances, i18n.language is undefined, so we need to provide a default value
  const currentLanguage = i18n.language || "de";

  const isLanguageSelected = (language: string) => {
    return currentLanguage.toUpperCase() === language.toUpperCase();
  };

  type LanguageOption = { value: string; label: string };

  const options = [
    { value: "de", label: "DE" },
    { value: "fr", label: "FR" },
    { value: "it", label: "IT" },
    { value: "en", label: "EN" },
    isFeatureEnabled("cimode") ? { value: "cimode", label: "CI" } : null,
  ].filter((it) => !!it) as LanguageOption[];

  const setLanguage = (language: string) => {
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  };

  return (
    <header className="pageHeader">
      <div className="metaline d-none d-md-block">
        <div className="container">
          <ul className="nav secondNav" role="navigation"></ul>
          <ul
            id="servicenavi"
            className="language-menu nav navlist justify-content-end">
            <li style={{ display: "flex", columnGap: "1rem" }}>
              {options.map((option) => (
                <a
                  className={`nav-link ${
                    isLanguageSelected(option.value) ? "active" : ""
                  }`}
                  key={option.value}
                  href={history.location.pathname}
                  onClick={(e) => {
                    e.preventDefault();
                    setLanguage(option.value);
                  }}>
                  {option.label}
                </a>
              ))}
            </li>
          </ul>
        </div>
      </div>
      <div className="mainline container d-flex">
        <div className="stickyline flex-column">
          <div className="zettelIcons d-flex justify-content-between align-items-center">
            <div className="myclimate-projectlogo">
              <Link to="/">
                <img
                  className="myclimate-logo"
                  src="/assets/images/myclimate_logo.svg"
                  alt=""
                />
              </Link>
            </div>
            <ul className="nav zettelNav">
              <li className="nav-item">
                <Link
                  to="/login"
                  style={{ visibility: menuOpen ? "hidden" : "visible" }}>
                  <span title="Profil" className="logout icn-anmelden"></span>
                </Link>
              </li>
              <ZettelNavigation
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                login={login}
              />
            </ul>
          </div>
          <div className="zettelBox">
            <Collapse in={menuOpen}>
              <div>
                <ul
                  id="nav-main"
                  className="collapse-padding navlist nav navbar-nav">
                  {(!login || !login.user) && <NavigationForGuests />}
                  {login && login.user && login.user.classId && (
                    <NavigationForStudents login={login} logout={logout} />
                  )}
                  {login && login.user && !login.user.classId && (
                    <NavigationForTeachers login={login} logout={logout} />
                  )}
                </ul>
              </div>
            </Collapse>
            <div
              className="navbar-collapse collapse show"
              id="navbarMenu"></div>
            <div className="collapse navbar-collapse" id="navbarLogin">
              <div className="collapse-padding"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
