import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  Column,
} from "react-table";
import {
  Leg,
  Transportation,
  TransportationVariant,
} from "sharedTypes/modelTypes";
import { addEmissions, totalEmissions } from "src/helpers/data";
import TransportDropdown from "./TransportDropdown";

interface MapLegsTableProps {
  legs: Leg[]; // Replace "Leg" with the actual type of your legs
  handleDistanceRequest: (
    leg: Leg,
    mode: string,
    transitMode?: string
  ) => Promise<any>;
  activeLegId: number | null;
  onRowClick: (id: number) => void;
  calculateFlyingDistance: (leg: Leg) => number;
  calculateSailingDistance: (leg: Leg) => number;
  noDistanceCalculation: (leg: Leg) => number;
  setHaveAllLegsTransportation: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditTripButtonClick: any;
}

interface RowOriginal {
  leg: {
    name: string;
    distanceInKm: number;
    transportation: Transportation;
    transportationVariant: TransportationVariant;
    origin: string;
  };
  emissions_in_kgCO2: number;
}

const MapLegsTable = ({
  legs,
  handleDistanceRequest,
  calculateFlyingDistance,
  calculateSailingDistance,
  noDistanceCalculation,
  setHaveAllLegsTransportation,
  activeLegId,
  onRowClick,
  handleEditTripButtonClick,
}: React.PropsWithChildren<MapLegsTableProps>): React.ReactElement<
  any,
  any
> => {
  // calculate emissions
  const legsWithEmissions = useMemo(() => addEmissions(legs), [legs]);

  const total = totalEmissions(legs);

  const [, setSelectStates] = useState<string[]>([]);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const allHaveCompleteTransport = legs.every((leg) => {
      if (
        leg.transportation === "bike" ||
        leg.transportation === "plane" ||
        leg.transportation === "train" ||
        leg.transportation === "ferry"
      ) {
        return (
          leg.transportationVariant !== "" &&
          typeof leg.transportationVariant === "string"
        );
      }
      return leg.transportation !== "";
    });
    setHaveAllLegsTransportation(allHaveCompleteTransport);
    // Clear the error when the component mounts or when legs change
    setError(null);
  }, [legs, setHaveAllLegsTransportation]);

  // Update the state of a specific dropdown
  const handleSelectChange = async (
    index: number,
    selectedOption: string,
    additionalInputValue: string
  ) => {
    try {
      setSelectStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = selectedOption;
        return newStates;
      });

      const leg = legs[index];
      const updatedLeg: Leg = {
        ...leg,
        transportation: selectedOption as Transportation,
        transportationVariant: additionalInputValue as TransportationVariant,
      };

      const modeMap: { [key: string]: string } = {
        car: "driving",
        eCar: "driving",
        motorcycle: "driving",
        miniBus: "driving",
        walk: "walking",
        bike: "cycling",
        train: "transit",
        travelBus: "transit",
        plane: "flying",
        ship: "sailing",
        ferry: "sailing",
        "": "none",
      };

      const mode = modeMap[selectedOption];

      // Helper function for handling transit mode
      const handleTransitMode = async () => {
        const transitMode =
          updatedLeg.transportation === "train" ? "train" : "bus";
        await handleDistanceRequest(updatedLeg, mode, transitMode);
      };

      // Helper function for handling basic modes
      const handleBasicMode = async () => {
        await handleDistanceRequest(updatedLeg, mode);
      };

      // Helper function for handling flying mode
      const handleFlyingMode = () => {
        calculateFlyingDistance(updatedLeg);
      };

      // Helper function for handling sailing mode
      const handleSailingMode = () => {
        calculateSailingDistance(updatedLeg);
      };

      const handleNoMode = () => {
        noDistanceCalculation(updatedLeg);
      };

      // Main logic based on mode
      switch (mode) {
        case "transit":
          await handleTransitMode();
          break;
        case "flying":
          handleFlyingMode();
          break;
        case "sailing":
          handleSailingMode();
          break;
        case "none":
          handleNoMode();
          break;
        default:
          handleBasicMode();
      }
    } catch (error) {
      console.error("Error handling distance Request", error);
      const errorMessage =
        (error as { response?: { data?: { error_message?: string } } })
          ?.response?.data?.error_message ||
        "Ein Fehler ist beim Abrufen der Distanzen aufgetreten. Bitte versuch es mit einer anderen Transportoption, da es möglich sein kann, dass es keine Strecken für die von dir gewählte gibt.";

      setError(errorMessage);
    }
  };

  const { t } = useTranslation("common");

  // Define columns for the table
  const columns: Array<Column<any>> = React.useMemo(
    () => [
      {
        Header: t("Teilstrecke"),
        accessor: (row, _ix) => row.leg.name,
      },
      {
        Header: t("Distanz"),
        accessor: (row, _ix) => row.leg.distanceInKm,
      },
      {
        Header: t("Transport"),
        accessor: "transportation",
      },
      {
        Header: () => (
          <span dangerouslySetInnerHTML={{ __html: t("CO2Person") }} />
        ),
        accessor: "emissions_in_kgCO2",
        Cell: ({ value }) => {
          return value.toFixed(3);
        },
      },
    ],
    [t]
  );

  // Create an instance of the useTable hook
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data: legsWithEmissions },
      useFilters,
      useGlobalFilter,
      useSortBy
    );

  return (
    <>
      {/* Display the error message if there is an error */}
      {error && (
        <div style={{ color: "red", margin: "10px" }}>Error: {error}</div>
      )}
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.column.id === "transportation" && (
                        <TransportDropdown
                          row={row.original as RowOriginal}
                          onSelectChange={(
                            selectedOption,
                            newAdditionalValue
                          ) =>
                            handleSelectChange(
                              row.index,
                              selectedOption,
                              newAdditionalValue
                            )
                          }
                          handleDistanceRequest={handleDistanceRequest}
                          calculateFlyingDistance={calculateFlyingDistance}
                          calculateSailingDistance={calculateSailingDistance}
                          noDistanceCalculation={noDistanceCalculation}
                          currentTransportation={
                            (row.original as RowOriginal).leg.transportation
                          }
                          currentTransportationVariant={
                            (row.original as RowOriginal).leg
                              .transportationVariant
                          }
                        />
                      )}
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {/* Render Total CO2 Emissionen */}
        <tfoot>
          <tr>
            <th
              style={{ textAlign: "right" }}
              colSpan={4}
              dangerouslySetInnerHTML={{
                __html: `${t("TotalCO2Emissionen")}: ${total.toFixed(3)} kg`,
              }}
            />
          </tr>
        </tfoot>
      </table>
      <div>
        <Button
          variant="primary"
          type="submit"
          onClick={handleEditTripButtonClick}>
          {t("common:ReiseAndern")}
        </Button>
      </div>
    </>
  );
};

export default MapLegsTable;
