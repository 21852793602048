import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import { Class } from "sharedTypes/modelTypes";
import { TLogin } from "src/@types";
import ClassDetail from "./ClassDetail";

type TClassesListProps = {
  classes: Class[] | null;
  login: TLogin;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalQrText: React.Dispatch<React.SetStateAction<string>>;
};
const ClassesList: React.FC<TClassesListProps> = ({
  classes,
  login,
  setShowModal,
  setModalQrText,
}) => {
  const { t } = useTranslation("common");
  // don't render the list while classes are being fetched
  if (classes === null) return null;
  if (classes.length === 0) return <p>{t("Keine Klassen")}</p>;
  return (
    <>
      <p>{t("Ihre Klassen Intro")}</p>
      <Accordion defaultActiveKey={`${classes[0].id}`}>
        {classes.map((clazz) => (
          <ClassDetail
            key={clazz.id}
            clazz={clazz}
            login={login}
            setShowModal={setShowModal}
            setModalQrText={setModalQrText}></ClassDetail>
        ))}
      </Accordion>
    </>
  );
};

export default ClassesList;
