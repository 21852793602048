import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TLogin } from "src/@types";

type TNavigationForStudentsProps = {
  login: TLogin;
  logout: () => void;
};
const NavigationForStudents: React.FC<TNavigationForStudentsProps> = ({
  login,
  logout,
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <li className="nav-item">
        <Link to="/" className="nav-link">
          {t("FurSchuler")}
        </Link>
        <ul className="lvl2 navlist">
          <li className="first nav-item">
            <Link to="/trip" className="nav-link">
              {t("MeineReise")}
            </Link>
          </li>
          <li className="last nav-item">
            <Link to={"/class/" + login.user.classId} className="nav-link">
              {t("MeineKlasse")}
            </Link>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <Button
          variant="outline-secondary"
          onClick={logout}
          className="nav-link mt-4">
          {t("Abmelden")}
        </Button>
      </li>
    </>
  );
};

export default NavigationForStudents;
