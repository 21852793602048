import { ScatterPlotCustomSvgLayer } from "@nivo/scatterplot";
import { Leg } from "sharedTypes/modelTypes";
import { addEmissions, kgToTonnes } from "src/helpers/data";
import { transportationColors } from "src/helpers/transportation";
import { TTripDatum } from "./ClassScatterPlot";

type TSvgLineValues = {
  startDist: number;
  startEm: number;
  endDist: number;
  endEm: number;
  color: string;
  opacity: number;
  label: string | null;
};
type ScaleFn = (x: number) => number;

type TSvgLineProps = {
  lineValues: TSvgLineValues;
  xScale: ScaleFn;
  yScale: ScaleFn;
};
const SvgLine: React.FC<TSvgLineProps> = ({ lineValues, xScale, yScale }) => {
  const x1 = isNaN(xScale(lineValues.startDist))
    ? 0
    : xScale(lineValues.startDist);
  const y1 = isNaN(yScale(lineValues.startEm)) ? 0 : yScale(lineValues.startEm);
  const x2 = isNaN(xScale(lineValues.endDist)) ? 0 : xScale(lineValues.endDist);
  const y2 = isNaN(yScale(lineValues.endEm)) ? 0 : yScale(lineValues.endEm);

  return (
    <>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={lineValues.color}
        strokeOpacity={lineValues.opacity}
        strokeWidth="4"></line>
      <text
        transform={`translate(${(x1 + x2) * 0.5}, ${(y1 + y2) * 0.5 + 10})`}
        dominantBaseline="middle"
        fill={lineValues.color}
        stroke="#FFFFFF"
        strokeWidth="0.6"
        fontWeight="bold"
        style={{ cursor: "default" }}>
        {lineValues.label}
      </text>
    </>
  );
};

type TUserSvgPathProps = {
  trip: ReturnType<typeof addEmissions>;
  xScale: ScaleFn;
  yScale: ScaleFn;
  selected: boolean;
  onClick: () => void;
};
const UserSvgPath: React.FC<TUserSvgPathProps> = ({
  trip,
  xScale,
  yScale,
  selected,
  onClick,
}) => {
  trip.sort((a, b) => (a.leg as Leg).id - (b.leg as Leg).id);
  let dist = 0;
  let em = 0;
  const pathValues = trip.map((entry) => {
    const startDist = dist;
    const startEm = em;
    dist += entry.leg.distanceInKm;
    em += kgToTonnes(entry.emissions_in_kgCO2);
    return {
      startDist,
      startEm,
      endDist: dist,
      endEm: em,
      color: transportationColors[entry.leg.transportation],
      opacity: selected ? 1 : 0.2,
      label: selected ? entry.leg.name : null,
    };
  });
  return (
    <g onMouseDown={onClick}>
      {pathValues.map((lineValues, index) => (
        <SvgLine
          key={index}
          lineValues={lineValues}
          xScale={xScale}
          yScale={yScale}></SvgLine>
      ))}
    </g>
  );
};

const UserTripsSvgLayer =
  (
    toggleSelectedStudent: (userId: number) => void
  ): ScatterPlotCustomSvgLayer<TTripDatum> =>
  (props) =>
    (
      <g>
        {props.nodes.map((nodeData, index) => (
          <UserSvgPath
            key={index}
            trip={nodeData.data.legsWithEmissions}
            xScale={props.xScale as any as ScaleFn}
            yScale={props.yScale as any as ScaleFn}
            selected={nodeData.data.selected}
            onClick={() => toggleSelectedStudent(nodeData.data.userId)}
          />
        ))}
      </g>
    );

export default UserTripsSvgLayer;
