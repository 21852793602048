import { useState, useEffect } from "react";
import { Leg } from "sharedTypes/modelTypes";
import { useTranslation } from "react-i18next";

import TripCalculator, {
  TLegFormValues,
} from "src/components/trip/TripCalculator";
import Analysis from "src/components/analysis/Analysis";
import { v4 as uuidv4 } from "uuid";

const TripViewSolo: React.FC = () => {
  const [showMap, setShowMap] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(false); // State to track whether to show Analysis component

  const initialLegs = (): Leg[] => {
    let legsJSON = localStorage.getItem("legs");
    return legsJSON ? JSON.parse(legsJSON) : [];
  };
  const [legs, setLegs] = useState(initialLegs);
  // persist changes as side-effects independent of rendering
  useEffect(() => {
    localStorage.setItem("legs", JSON.stringify(legs));
  }, [legs]);

  // find leg and update it
  const updateLeg = (id: number, leg: Leg) => {
    let localIndex = legs.findIndex((element) => element.id === leg.id);
    let nextLegs = [...legs];
    leg.updatedAt = new Date();
    // replace copied leg with argument leg at same index
    nextLegs.splice(localIndex, 1, leg);
    setLegs(nextLegs);
    return legs[localIndex];
  };

  // add uuid to new leg and append to legs
  const createLeg = (newLeg: TLegFormValues) => {
    const legToAdd = {
      ...newLeg,
      id: parseInt(uuidv4(), 16),
      createdAt: new Date(),
      updatedAt: new Date(),
      userId: 0,
    };
    setLegs([...legs, legToAdd]);
    return legToAdd;
  };

  const removeLeg = (id: number) => {
    setLegs((prevLegs) => {
      // Find the Leg object with the matching id
      const legToRemove = prevLegs.find((element) => element.id === id);

      if (!legToRemove) {
        console.error(`Leg with id ${id} not found in prevLegs.`);
        return prevLegs;
      }

      // Create a new array without the found Leg object
      const nextLegs = prevLegs.filter((element) => element.id !== id);

      // Update localStorage with the updated legs
      localStorage.setItem("legs", JSON.stringify(nextLegs));

      return nextLegs;
    });
  };

  const { t } = useTranslation("tripViewSolo");

  return (
    <>
      <h1 className="h2">{t("Titel")}</h1>
      <TripCalculator
        legs={legs}
        createLeg={createLeg}
        updateLeg={updateLeg}
        removeLeg={removeLeg}
        origin={""}
        destination={""}
        setShowAnalysis={setShowAnalysis}
        showMap={showMap}
        showAnalysis={showAnalysis}
        setShowMap={setShowMap}></TripCalculator>
      {showAnalysis && !showMap ? (
        <Analysis legs={legs} defaultWaffleSizeKg={1} />
      ) : null}
    </>
  );
};

export default TripViewSolo;
