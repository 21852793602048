import React from "react";
import { useTranslation } from "react-i18next";
import { getTransportationLabels } from "src/helpers/transportation";

export const TransportationSelectOptions: React.FC = () => {
  const { t } = useTranslation("common");

  const labels = getTransportationLabels(t);
  return (
    <>
      {Object.keys(labels).map((key) => (
        <option key={key} value={key}>
          {labels[key as keyof typeof labels]}
        </option>
      ))}
    </>
  );
};

export default TransportationSelectOptions;
