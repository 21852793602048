import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { Leg } from "sharedTypes/modelTypes";
import { addEmissions, totalEmissions } from "src/helpers/data";
import { getTransportationLabel } from "src/helpers/transportation";

type TTripConciseProps = {
  name: string;
  destination: string;
  legs: Leg[];
  onClick: () => void;
  active: boolean;
};
const TripConcise: React.FC<TTripConciseProps> = ({
  name,
  destination,
  legs,
  onClick,
  active,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className="mt-2">
      <div className="d-flex align-items-center">
        <div className="mr-2">
          <input type="checkbox" defaultChecked={active} onClick={onClick} />
        </div>
        <div
          className="w-100 d-flex justify-content-between"
          style={{
            fontWeight: "bold",
          }}>
          {destination && (
            <span>
              {destination} ({name})
            </span>
          )}
          {!destination && <span>{name}</span>}
          <div>
            {Math.round(totalEmissions(legs))} {t("Kg")} CO
            <sub>2</sub>
          </div>
        </div>
      </div>
      {addEmissions(legs).map((data) => (
        <Row key={data.leg.id}>
          <Col>{data.leg.name}</Col>
          <Col>
            {data.leg.distanceInKm} {t("Km")}
          </Col>
          <Col>{getTransportationLabel(t, data.leg.transportation)}</Col>
          <Col>
            {Math.round(data.emissions_in_kgCO2)} kg CO<sub>2</sub>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default TripConcise;
