const earthRadius = 6378.38;

function deg2rad(degree: number): number {
  return degree * (Math.PI / 180.0);
}

function parseCoordinates(coordinates: string): { latitude: number, longitude: number } {
  const [lat, lon] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
  return { latitude: lat, longitude: lon };
}

function calculateDistance(from: { latitude: number, longitude: number }, to: { latitude: number, longitude: number }): number {
  const latFrom = Math.PI / 2 - deg2rad(90 - from.latitude);
  const latTo = Math.PI / 2 - deg2rad(90 - to.latitude);
  const lonFrom = deg2rad(from.longitude);
  const lonTo = deg2rad(to.longitude);

  const totalDistance = earthRadius * Math.acos(
    Math.cos(latFrom) * Math.cos(latTo) * Math.cos(lonFrom - lonTo) + Math.sin(latFrom) * Math.sin(latTo)
  );
  const distance = Math.round(totalDistance);

  return distance;
}

 function flyingDistanceBetweenCoords(...coords: string[]): number {
  let distance = 0;
  for (let i = 0; i < coords.length - 1; i++) {
    const from = parseCoordinates(coords[i]);
    const to = parseCoordinates(coords[i + 1]);
    distance += calculateDistance(from, to);
  }
  return distance;
}
export default flyingDistanceBetweenCoords;