import { useTranslation } from "react-i18next";
import { ResponsivePie } from "@nivo/pie";
import { Leg, Transportation } from "sharedTypes/modelTypes";
import { transportationColors } from "src/helpers/transportation";
import { getTransportationLabel } from "src/helpers/transportation";

type TDistancePerTransportPieProps = {
  legs: Leg[];
};
const DistancePerTransportPie: React.FC<TDistancePerTransportPieProps> = ({
  legs,
}) => {
  const distanceByTransport = legs.reduce((accumulator, currentValue) => {
    if (!accumulator[currentValue.transportation]) {
      accumulator[currentValue.transportation] = 0.0;
    }
    accumulator[currentValue.transportation] += currentValue.distanceInKm;
    return accumulator;
  }, {} as Record<Transportation, number>);

  const totalDistance = Object.values(distanceByTransport).reduce(
    (sum, value) => sum + value,
    0
  );

  const { t } = useTranslation("common");

  const pieData = (Object.keys(distanceByTransport) as Transportation[]).map(
    (transport) => {
      return {
        id: transport,
        transportation: transport,
        label: getTransportationLabel(t, transport as Transportation),
        value: distanceByTransport[transport],
      };
    }
  );

  // Set the innerRadius to a value greater than 0 to create a donut chart effect.
  const innerRadius = 0.5;

  return (
    <div
      style={{
        width: "100%",
        height: "300px",
        position: "relative",
        marginTop: "4rem",
      }}>
      <ResponsivePie
        data={pieData}
        innerRadius={innerRadius}
        padAngle={0.7}
        cornerRadius={3}
        colors={(node) => transportationColors[node.data.transportation]}
        arcLabel={(leg) => `${Math.trunc(leg.value)} km`}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#222222"
        enableArcLinkLabels={false}
        tooltip={({ datum: { label, value } }) => {
          return (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "3px",
                padding: "0.4rem",
              }}>
              <b>{label}</b>
              <br />
              {Math.trunc(value)} km
            </div>
          );
        }}
        animate={true}
        motionConfig="stiff"
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "1rem",
          fontWeight: "bold",
        }}>
        Total:
        <br />
        {Math.trunc(totalDistance)} km
      </div>
    </div>
  );
};

export default DistancePerTransportPie;
