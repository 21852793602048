import { useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import { UserWithPin } from "sharedTypes/modelTypes";

const UserPin: React.FC<{ user: UserWithPin }> = ({ user }) => {
  const [open, setOpen] = useState(false);

  return (
    <Row className="mt-2">
      <Col>
        <Row>
          <Col>
            <Button
              variant="link"
              onClick={() => {
                setOpen(!open);
              }}>
              {user.name}
            </Button>
          </Col>
        </Row>
        <Collapse in={open}>
          <Row>
            <Col>
              Login PIN: {user.userpin.pin.toString().length < 4 ? "0" : ""}
              {user.userpin.pin}
            </Col>
          </Row>
        </Collapse>
      </Col>
    </Row>
  );
};

const Pins: React.FC<{ users: UserWithPin[] }> = ({ users }) => {
  return (
    <>
      {users.map((user) => (
        <UserPin key={user.id} user={user} />
      ))}
    </>
  );
};

export default Pins;
