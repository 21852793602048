import React from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

import TransportationInput from "./TransportationInput";
import TransportationVariantInput from "./TransportationVariantInput";
import { TLegFormValues } from "./TripCalculator";

type TLegFormFieldsProps = {
  onSubmit: SubmitHandler<TLegFormValues>;
  defaultValues: Partial<TLegFormValues>;
};
const LegFormFields: React.FC<TLegFormFieldsProps> = ({
  onSubmit,
  children,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TLegFormValues>({
    defaultValues,
  });

  // react-hook-form passes all input fields as strings .. convert here while submitting for now
  const convertAndSubmit: SubmitHandler<TLegFormValues> = (data) => {
    data.distanceInKm = Number(data.distanceInKm);
    onSubmit(data);
  };

  //   used for conditionally showing range slider or select depending on transportation.
  const selectedTransportation = watch("transportation");
  const selectedTransportationVariant = watch("transportationVariant");

  const { t } = useTranslation("tripViewSolo");

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: t("TabelleIntro") }} />
      {/* Leg Name */}
      <Form onSubmit={handleSubmit(convertAndSubmit)}>
        <Form.Group controlId="formLegName">
          <Form.Label>{t("common:Strecke")}</Form.Label>
          <Form.Control
            type="text"
            placeholder="Zürich - Basel"
            {...register("name", { required: true })}
          />
        </Form.Group>
        {/* Leg Distance */}
        <Form.Group controlId="formLegDistance">
          <Form.Label>{t("common:Distanz")}</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              {...register("distanceInKm", { required: true })}
            />
            <InputGroup.Append>
              <InputGroup.Text id="appendKm">{t("common:Km")}</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        {/* Transportation */}
        <Form.Row>
          <TransportationInput
            register={register}
            transportation={selectedTransportation}
            variant={selectedTransportationVariant}
          />
          <TransportationVariantInput
            transportation={selectedTransportation}
            register={register}
            errors={errors}
            watch={watch}
          />
        </Form.Row>
        {children}
      </Form>
    </>
  );
};

export default LegFormFields;
