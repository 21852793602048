type IsFeatureEnabled = (name: string, url?: string) => boolean;

const isFeatureEnabled: IsFeatureEnabled = (
  name,
  url = window.location.href
) => {
  try {
    const featuresString =
      sessionStorage.getItem("features") ||
      localStorage.getItem("features") ||
      "";
    if (featuresString === "all") {
      return true;
    }
    const features = featuresString.split(",");
    return contains(features, name);
  } catch (_err) {
    return false;
  }
};

// Replacement for Array.prototype.includes to avoid changing Typescript settings or adding polyfills
const contains = (array: string[], value: string): boolean => {
  return array.indexOf(value) !== -1;
};

export default isFeatureEnabled;
