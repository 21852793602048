import React from "react";
import { useTranslation } from "react-i18next";
import {
  ResponsiveScatterPlot,
  ScatterPlotNode,
  ScatterPlotRawSerie,
  ScatterPlotMouseHandler,
  ScatterPlotTooltip,
} from "@nivo/scatterplot";

import {
  groupBy,
  addEmissions,
  kgToTonnes,
  sum,
  getEmissionFactor,
} from "../../helpers/data";
import {
  getTransportationLabel,
  transportationColors,
} from "../../helpers/transportation";
import Row from "react-bootstrap/Row";
import {
  Leg,
  Transportation,
  TransportationVariant,
  User,
} from "sharedTypes/modelTypes";
import { Col } from "react-bootstrap";
import TransportationColorLegend from "../analysis/TransportationColorLegend";
import UserTripsSvgLayer from "./UserTripsSvgLayer";

const getTransportationWithLongestDistance = (
  entries: ReturnType<typeof addEmissions>
) => {
  let groupedByTransportation = groupBy(
    entries,
    (entry) => entry.leg.transportation
  );

  return Object.entries(groupedByTransportation).reduce<{
    transportation: string | null;
    distance: number;
  }>(
    ({ transportation, distance }, group) => {
      let currDistance = sum(group[1], (entry) => entry.leg.distanceInKm);
      if (distance < currDistance) {
        transportation = group[0];
        distance = currDistance;
      }
      return { transportation, distance };
    },
    { transportation: null, distance: 0 }
  ).transportation as Transportation | null;
};

export type TTripDatum = {
  x: number;
  y: number;
  color: string;
  destination: string | null;
  userId: number;
  selected: boolean;
  legsWithEmissions: {
    leg: Leg;
    emissions_in_kgCO2: number;
  }[];
};
const getClassData = (
  legs: Leg[],
  users: User[],
  selectedStudentId: number | null
): ScatterPlotRawSerie<TTripDatum>[] => {
  const legsGroupedByUser = groupBy(
    addEmissions(legs),
    (x) => `${x.leg.userId}`
  );
  return users.map((user) => {
    const legsWithEmissions = legsGroupedByUser[user.id] || [];
    const transportationWithLongestDistance =
      getTransportationWithLongestDistance(legsWithEmissions);
    return {
      id: user.name || "-unknown-",
      data: [
        {
          x: sum(legsWithEmissions, (entry) => entry.leg.distanceInKm),
          y: kgToTonnes(
            sum(legsWithEmissions, (entry) => entry.emissions_in_kgCO2)
          ),
          color: transportationWithLongestDistance
            ? transportationColors[transportationWithLongestDistance]
            : "white",
          destination: user.destination || user.name,
          userId: user.id,
          selected: user.id === selectedStudentId,
          legsWithEmissions,
        },
      ],
    };
  });
};

type TClassScatterPlotProps = {
  legs: Leg[];
  users: User[];
  selectedStudentId: number | null;
  toggleSelectedStudent: (userId: number) => void;
};
const ClassScatterPlot: React.FC<TClassScatterPlotProps> = ({
  legs,
  users,
  selectedStudentId,
  toggleSelectedStudent,
}) => {
  const { t } = useTranslation("common");
  const classData = getClassData(legs, users, selectedStudentId);
  const nodeComponent: ScatterPlotNode<TTripDatum> = ({
    node,
    style,
    blendMode,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
  }) => (
    <g
      transform={`translate(${node.x}, ${node.y})`}
      fill={node.data.color}
      onClick={(e) => onClick?.(node, e)}
      onMouseEnter={(e) => onMouseEnter?.(node, e)}
      onMouseLeave={(e) => onMouseLeave?.(node, e)}
      onMouseMove={(e) => onMouseMove?.(node, e)}
      style={{ mixBlendMode: blendMode }}>
      <circle cx="0" cy="0" r={style.size.get() / 2} />
      <text
        transform="translate(10, 0)"
        dominantBaseline="middle"
        style={{ cursor: "default" }}
        stroke="#FFFFFF"
        strokeWidth="0.2">
        {(node.data as any).destination}
      </text>
    </g>
  );

  const tooltip: ScatterPlotTooltip<TTripDatum> = ({ node }) => {
    let formattedY;

    if (typeof node.formattedY === "number") {
      formattedY = node.formattedY.toFixed(3).toString();
    } else if (typeof node.formattedY === "string") {
      // Extract the numeric part and round it to 3 decimal places
      const match = node.formattedY.match(/^-?\d+\.\d+/);
      formattedY = match
        ? parseFloat(match[0]).toFixed(3) + " tCO2/Person"
        : "Invalid Format";
    } else {
      formattedY = "Invalid Format";
    }
    return (
      <div
        className="bg-white"
        style={{
          borderRadius: "2px",
          boxShadow: "rgba(0,0,0,0.25) 0px 1px 2px",
          padding: "5px 9px",
        }}>
        <b>{node.data.destination || node.serieId}</b>
        <br />
        {node.formattedX}, {formattedY}
      </div>
    );
  };
  const nodeMouseHandler: ScatterPlotMouseHandler<TTripDatum> = (
    node,
    event
  ) => {
    toggleSelectedStudent((node.data as any).userId);
  };
  return (
    <>
      <Row>
        <Col style={{ height: "min(90vh, 800px)" }}>
          <ResponsiveScatterPlot
            data={classData}
            margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
            xScale={{ type: "linear", min: 0, max: "auto" }}
            xFormat={(e) => e + " km"}
            yScale={{ type: "linear", min: 0, max: "auto" }}
            yFormat={(e) => e + " tCO2/Person"}
            nodeSize={13}
            blendMode="multiply"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: t("Gesamtdistanz"),
              legendPosition: "middle",
              legendOffset: 46,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: t("CO2EmissionenDerReise"),
              legendPosition: "middle",
              legendOffset: -60,
            }}
            nodeComponent={nodeComponent}
            tooltip={tooltip}
            onClick={nodeMouseHandler}
            //colors={{ datum: 'color' }}
            layers={[
              "grid",
              "axes",
              UserTripsSvgLayer(toggleSelectedStudent),
              "nodes",
            ]}
          />
        </Col>
      </Row>
      <TransportationColorLegend />
    </>
  );
};

export default ClassScatterPlot;

// the layer API of Nivo is not yet documented
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReferenceSlopes = ({ xScale, yScale }: any): React.ReactNode => {
  const { t } = useTranslation("common");

  const [xDomainMin, xDomainMax] = xScale.domain();
  const [yDomainMin] = yScale.domain();

  const references: [Transportation, TransportationVariant][] = [
    ["train", "switzerland"],
    ["plane", "shortDistance"],
    ["car", "1"],
  ];

  const SvgLine = ({
    slope,
    label,
    color,
  }: {
    slope: number;
    label: string;
    color: string;
  }) => (
    <line
      // the SVG's origin is top-left .. the ScatterPlot's origin is bottom-left. use xScale, yScale to convert chart data to SVG coordinates.
      x1={xScale(xDomainMin)}
      y1={yScale(yDomainMin)}
      x2={xScale(xDomainMax)}
      y2={yScale(kgToTonnes(xDomainMax * slope))}
      stroke={color}
      strokeWidth="2">
      <title>{label}</title>
    </line>
  );

  return (
    <g>
      {references.map(([transportation, transportationVariant], index) => (
        <SvgLine
          key={index}
          label={`${getTransportationLabel(
            t,
            transportation
          )}, ${getTransportationLabel(
            t,
            transportation,
            transportationVariant
          )}`}
          slope={getEmissionFactor(transportation, transportationVariant)}
          color={transportationColors[transportation]}></SvgLine>
      ))}
    </g>
  );
};
