import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useFind, useMutation } from "figbird";
import { useForm } from "react-hook-form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import TripCalculator from "src/components/trip/TripCalculator";

import { Form } from "react-bootstrap";
import { VscEdit } from "react-icons/vsc";

import client from "src/helpers/feathers";
import Analysis from "src/components/analysis/Analysis";
import { Leg, User } from "sharedTypes/modelTypes";

type TTripFormValues = {
  destination: string;
};

const TripViewStudent: React.FC<{ login: { user: User } }> = ({ login }) => {
  // get data that needs to be displayed in this view
  const { data: legs } = useFind<Leg>("api/legs", {
    query: { userId: login.user.id },
  });

  // get hooks for all the mutations via backend and database
  const {
    create: createLeg,
    update: updateLeg,
    remove: removeLeg,
  } = useMutation<Leg>("api/legs");

  const [editDestination, setEditDestination] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(false); // State to track whether to show Analysis component

  const updateDestination = ({ destination }: { destination: string }) =>
    client
      .service("api/trips")
      .patch(login.user.id, { destination })
      .then(() => {
        setEditDestination(false);
        login.user.destination = destination;
      });

  const { register, handleSubmit, formState } = useForm<TTripFormValues>();

  const { t } = useTranslation("common");

  return (
    <>
      <h1 className="h2">Los geht’s! Stelle deine Reise zusammen!</h1>
      <Form onSubmit={handleSubmit(updateDestination)}>
        <Form.Group>
          <Form.Label>Reisedestination</Form.Label>
          {editDestination && (
            <input
              type="text"
              defaultValue={login.user.destination ?? ""}
              {...register("destination", { required: true })}
            />
          )}
          {!editDestination && (
            <div>
              <span className="mr-2">{login.user.destination}</span>
              <Button
                variant="link"
                className="text-secondary"
                style={{ marginBottom: "0.7rem" }}
                onClick={() => setEditDestination(true)}>
                <VscEdit />
              </Button>
            </div>
          )}
        </Form.Group>
        {editDestination && (
          <Row>
            <Col>
              <Button
                variant="outline-secondary"
                onClick={() => setEditDestination(false)}>
                {t("Abbrechen")}
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                type="submit"
                disabled={formState.isSubmitting}>
                {t("Speichern")}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
      <TripCalculator
        legs={legs ?? []}
        createLeg={createLeg}
        updateLeg={updateLeg}
        removeLeg={removeLeg}
        origin={""}
        destination={""}
        setShowAnalysis={setShowAnalysis}
        setShowMap={setShowMap}
        showMap={showMap}
        showAnalysis={showAnalysis}></TripCalculator>
      {showAnalysis && !showMap ? <Analysis legs={legs ?? []} /> : null}
    </>
  );
};

export default TripViewStudent;
