import { useTranslation } from "react-i18next";
import { BarDatum, ResponsiveBar } from "@nivo/bar";
import _ from "lodash";
import { Leg, Transportation } from "sharedTypes/modelTypes";

import { addEmissions, kgToTonnes } from "src/helpers/data";

import {
  transportationColors,
  getTransportationLabel,
  getTransportationLabels,
} from "src/helpers/transportation";

type TComparisonChartProps = {
  legs: Leg[];
};
const ComparisonChart: React.FC<TComparisonChartProps> = ({ legs }) => {
  const { t } = useTranslation("common");

  const emissionsInGByTransport = addEmissions(legs).reduce(
    (accumulator, currentValue) => {
      if (!accumulator[currentValue.leg.transportation]) {
        accumulator[currentValue.leg.transportation] = 0.0;
      }
      accumulator[currentValue.leg.transportation] +=
        currentValue.emissions_in_kgCO2;
      return accumulator;
    },
    {} as Record<string, number>
  );

  const emissionsInTByTransport = _.mapValues(
    emissionsInGByTransport,
    (value) => kgToTonnes(value)
  );
  const total = _.sum(Object.values(emissionsInTByTransport));

  let data: BarDatum[] = [
    {
      category: t("Bekleidung und Schuhe"),
      type: "Bekleidung und Schuhe",
      total: 0.465,
    },
    {
      category: t("Restaurants und Hotels"),
      type: "Restaurant und Hotels",
      total: 0.507,
    },
    {
      category: t("Gesundheitspflege"),
      type: "Gesundheitspflege",
      total: 0.629,
    },
    {
      category: t("Unterhaltung, Erholung und Kultur"),
      type: "Unterhaltung, Erholung und Kultur",
      total: 0.663,
    },
    {
      category: t("Verschiedene Güter und Dienstleistungen"),
      type: "Verschiedene Güter und Dienstleistungen",
      total: 0.823,
    },
    {
      category: t("Wohnen"),
      type: "Wohnen",
      total: 1.815,
    },
    {
      category: t("Lebensmittel"),
      type: "Lebensmittel",
      total: 1.843,
    },
    {
      category: t("MeineReise"),
      type: "Meine Reise",
      ...emissionsInTByTransport,
      total: total,
    },
  ].sort((first, second) => first.total - second.total);
  data = data.map((entry) => {
    if (entry.type === "Meine Reise") {
      delete entry.total;
    }
    return entry;
  });

  const myTravelIndex = _.findIndex(
    data,
    (entry) => entry.type === "Meine Reise"
  );
  const transports = Object.keys(getTransportationLabels(t));
  transports.splice(myTravelIndex, 0, "total");

  return (
    <ResponsiveBar
      data={data}
      layout="horizontal"
      keys={transports}
      indexBy="category"
      label={(leg) => `${leg.value!.toFixed(1)} t`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      gridYValues={data.map((entry) => entry.category as string)}
      margin={{ top: 0, right: 0, bottom: 0, left: 200 }}
      padding={0.3}
      labelTextColor={(datum) =>
        datum.data.id === "total" ? "black" : "white"
      }
      colors={(node) =>
        transportationColors[node.id as Transportation] || "#5BC5F2"
      }
      tooltip={({ id, value }) => (
        <>
          {id !== "total" && (
            <>
              <span>{getTransportationLabel(t, id as Transportation)}</span>
              <br />
            </>
          )}
          <b>{value} t</b>
        </>
      )}
    />
  );
};

export default ComparisonChart;
