import * as geolib from 'geolib';
/* Calculating distances for ship navigation involves different considerations compared to 
flight distances. While flight distances are typically calculated on a spherical Earth model
 using great-circle distances, ship distances often take into account the actual route a 
 ship might follow along coastlines and navigable waterways. One commonly used method for
ship navigation is to calculate distances along the surface of an ellipsoidal Earth model. 

geolib is a library that considers the Earth's ellipsoidal shape.
*/

function parseCoordinates(coordinates: string): { latitude: number, longitude: number } {
  const [lat, lon] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
  return { latitude: lat, longitude: lon };
}

 function calculateNavigatingDistance(...coords: string[]): number{
  let distance = 0;
  for (let i = 0; i < coords.length - 1; i++) {
    const from = parseCoordinates(coords[i]);
    const to = parseCoordinates(coords[i + 1]);
    const distanceInMeters = geolib.getDistance(from, to);
    distance += distanceInMeters/1000 // convert it to Km
  }
  return Math.round(distance);
}

export default calculateNavigatingDistance;