import { useTranslation } from "react-i18next";
import { Col, Form } from "react-bootstrap";
import { UseFormRegister } from "react-hook-form";
import { Transportation, TransportationVariant } from "sharedTypes/modelTypes";
import { getEmissionFactor } from "src/helpers/data";
import { TLegFormValues } from "./TripCalculator";
import TransportationSelectOptions from "./TransportationSelectOptions";

type TEmissionFactorProps = {
  transportation: Transportation;
  variant: TransportationVariant;
};
const EmissionFactor: React.FC<TEmissionFactorProps> = ({
  transportation,
  variant,
}) => {
  const factor = getEmissionFactor(transportation, variant);
  if (typeof factor == "number") {
    return (
      <p className="text-muted">
        {factor} kg CO<sub>2</sub> / Personenkm
      </p>
    );
  } else {
    return null;
  }
};

type TTransportationInputProps = {
  register: UseFormRegister<TLegFormValues>;
  transportation: Transportation;
  variant: TransportationVariant;
};
const TransportationInput: React.FC<TTransportationInputProps> = ({
  register,
  transportation,
  variant,
}) => {
  const { t } = useTranslation("common");

  return (
    <Form.Group as={Col} controlId="formLegTransportation">
      <Form.Label>{t("Transportmittel")}</Form.Label>
      <div className="select-wrapper">
        <Form.Control as="select" {...register("transportation")}>
          <TransportationSelectOptions />
        </Form.Control>
      </div>
      <EmissionFactor transportation={transportation} variant={variant} />
    </Form.Group>
  );
};

export default TransportationInput;
