import { useTranslation } from "react-i18next";
import { DisplayType } from "./Analysis";

type TTabProps = {
  type: DisplayType;
  title: string;
  isActive: boolean;
  onClick: (type: DisplayType) => void;
};
const Tab: React.FC<TTabProps> = ({ type, title, isActive, onClick }) => (
  <li className="nav-item">
    <a
      className={"nav-link " + (isActive ? "active" : "")}
      onClick={() => onClick(type)}
      href={`#${title}`}>
      {title}
    </a>
  </li>
);

type TTabsProps = {
  selected: DisplayType;
  onSelected: (type: DisplayType) => void;
};
const Tabs: React.FC<TTabsProps> = ({ onSelected, selected }) => {
  const { t } = useTranslation("common");

  return (
    <ul className="nav nav-tabs mt-4">
      <Tab
        type="trip"
        title={t("EmissionenProTransportmittel")}
        isActive={selected === "trip"}
        onClick={onSelected}
      />
      <Tab
        type="comparison"
        title={t("EmissionenImVergleich")}
        isActive={selected === "comparison"}
        onClick={onSelected}
      />
    </ul>
  );
};

export default Tabs;
