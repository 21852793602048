import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { Leg } from "sharedTypes/modelTypes";
import ComparisonChart from "./ComparisonChart";
import TransportBubbleChart from "./TransportBubbleChart";
import TransportationColorLegend from "./TransportationColorLegend";

type TAnalysisComparisonProps = {
  legs: Leg[];
};

const AnalysisComparison: React.FC<TAnalysisComparisonProps> = ({ legs }) => {
  const { t } = useTranslation("tripViewSolo");

  return (
    <div className="info-box">
      <Row>
        {/* need a fixed height for Responsive nivo Charts */}
        <Col xs={12}>
          <b dangerouslySetInnerHTML={{ __html: t("VergleichTitel") }} />
          <p dangerouslySetInnerHTML={{ __html: t("VergleichIntro") }} />
          <div className="mt-4" style={{ height: "40vh" }}>
            <ComparisonChart legs={legs} />
          </div>
          <div className="mt-4">
            <TransportationColorLegend />
          </div>
        </Col>
        <Col xs={12} className="mt-5">
          <b dangerouslySetInnerHTML={{ __html: t("VergleichTitel2") }} />
          <p dangerouslySetInnerHTML={{ __html: t("VergleichIntro2") }} />
          <div style={{ height: "70vh" }}>
            <TransportBubbleChart />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default AnalysisComparison;
