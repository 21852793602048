import { Leg, Transportation } from "sharedTypes/modelTypes";

// getEmissionFactor('walk') === 0
// getEmissionFactor('train','germany') === 60

export type EmissionFactors = {
  [K in Transportation]: Record<string | number, number> | number;
};

export const getEmissionFactor = (transportation: Transportation, variant: string | number | null) => {
  const emissionFactors: EmissionFactors = {
    "": 0,
    train: {
      "": 0,
      germany: 0.0408,
      switzerland: 0.0070,
      france: 0.0125,
      italy: 0.0747,
      austria: 0.0151,
      slovenia: 0.0568,
      croatia: 0.0637,
      greatBritain: 0.0496,
      sweden: 0.0085,
      norway: 0.0243,
      europe: 0.0360,
    },
    bike: { "": 0, bike: 0.0056, eBike: 0.0129 },
    walk: 0,
    plane: {
      "": 0,
      shortDistance: 0.3108,
      longDistance: 0.1989,
    },
    travelBus: 0.0465,
    ship: 0.1613,
    car: { '1': 0.3556, '2': 0.1778, '3': 0.1185, '4': 0.0889, '5': 0.0711 },
    miniBus: {
      1: 0.3843,
      2: 0.1922,
      3: 0.1281,
      4: 0.0961,
      5: 0.0769,
      6: 0.0641,
      7: 0.0549,
      8: 0.0480,
      9: 0.0427,
      10: 0.0384,
      11: 0.0349,
      12: 0.0320,
      13: 0.0296,
    },
    eCar: { 1: 0.1559, 2: 0.0780, 3: 0.0520, 4: 0.0390, 5: 0.0312 },
    motorcycle: { 1: 0.2240, 2: 0.1120 },
    ferry: { "": 0, walking: 0.0230, driving: 0.1586 }
  }

  let transportationValue = emissionFactors[transportation]
  if (typeof transportationValue === 'object') {
    return (transportationValue as unknown as Record<string, number>)[variant ?? 0]
  }
  else return transportationValue
};


export const addEmissions = (legs: Leg[]) => {
  return legs.map((cur) => {
    let { transportation, transportationVariant, distanceInKm } = cur;
    let kgCO2PerKm = getEmissionFactor(transportation, transportationVariant);
    return {
      leg: cur,
      emissions_in_kgCO2: distanceInKm * kgCO2PerKm,
    };
  });
};

export const totalEmissions = (legs: Leg[]) => {
  return legs.reduce((acc, cur) => {
    let emFactor = getEmissionFactor(cur.transportation, cur.transportationVariant)
    return acc + cur.distanceInKm * emFactor
  }, 0);
}

// unit conversion
export const gToKg = (x: string | number): number => {
  const parsedX = Number.parseFloat(x as string);
  return parsedX !== 0 ? parsedX / 1000 : 0;
};

export const gToTonnes = (x: string | number): number => {
  const parsedX = Number.parseFloat(x as string);
  // Check if parsedX is not zero to avoid division by zero
  return parsedX !== 0 ? parsedX / 1000000 : 0;
};

export const kgToTonnes = (x: string | number): number => {
  const parsedX = Number.parseFloat(x as string);
  // Check if parsedX is not zero to avoid division by zero
  return parsedX !== 0 ? parsedX / 1000 : 0;
};

export const groupByAndSum =
  <T>(objectArray: T[], propToGrp: (t: T) => string, propToSum: (t: T) => number) => {
    return objectArray.reduce((acc, obj) => {
      let key = propToGrp(obj);
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += propToSum(obj);
      return acc;
    }, {} as Record<string, number>)
  };

export const sum = <T>(objectArray: T[], propToSum: (t: T) => number) => {
  return objectArray.reduce((acc, obj) => {
    return acc + propToSum(obj)
  }, 0)
};

export const groupBy =
  <T>(objectArray: T[], propToGrp: (t: T) => string) => {
    return objectArray.reduce((acc, obj) => {
      let key = propToGrp(obj);
      if (!acc[key]) {
        acc[key] = [obj];
      }
      else {
        acc[key].push(obj);
      }
      return acc;
    }, {} as Record<string, T[]>)
  };

export const nextPerfectSquare = (n: number) => {
  if (Math.sqrt(n) * Math.sqrt(n) === n) {
    return n;
  } else {
    return Math.pow(Math.floor(Math.sqrt(n)) + 1, 2);
  }
};
