// Transportation

import { Transportation, TransportationVariant } from "sharedTypes/modelTypes";

type NoTransportation = "none";

export const getTransportationLabels = (t: any) => ({
  "": "",
  train: t("common:Zug"),
  bike: t("common:Fahrrad"),
  walk: t("common:zuFuss"),
  plane: t("common:Flugzeug"),
  travelBus: t("common:Reisebus"),
  ship: t("common:Schiff"),
  car: t("common:Auto"),
  miniBus: t("common:Minibus"),
  eCar: t("common:Elektroauto"),
  motorcycle: t("common:Motorrad"),
  ferry: t("common:Fähre"),
});

// Transportation Variant

const transportationVariantLabels = (t: any) => ({
  "": "",
  train: {
    germany: t("common:Deutschland"),
    switzerland: t("common:Schweiz"),
    france: t("common:Frankreich"),
    italy: t("common:Italien"),
    austria: t("common:Österreich"),
    slovenia: t("common:Slowenien"),
    croatia: t("common:Kroatien"),
    greatBritain: t("common:Grossbritannien"),
    sweden: t("common:Schweden"),
    norway: t("common:Norwegen"),
    europe: t("common:Europa"),
  },
  bike: {
    bike: t("common:KonventionellesFahrrad"),
    eBike: t("common:E-Bike"),
  },
  walk: {},
  plane: {
    shortDistance: t("common:FlugInnerhalbEuropa"),
    longDistance: t("common:FlugInterkontinental"),
  },
  travelBus: {},
  ship: {},
  car: {
    1: "1",
  },
  miniBus: {
    1: "1",
  },
  eCar: { 1: "1" },
  motorcycle: { 1: "1" },
  ferry: {
    walking: t("common:zuFuss"),
    driving: t("common:mitAuto"),
  },
});

// getTransportationLabel(t, 'train') === 'Zug'
// getTransportationLabel(t, 'train','germany') === 'Deutschland'
export const getTransportationLabel = (
  t: any,
  transportation: Transportation,
  variant?: TransportationVariant
) => {
  const variantLabel =
    variant &&
    (transportationVariantLabels(t)[transportation] as Record<string, string>)[
      variant as string
    ];
  return variantLabel
    ? variantLabel
    : (getTransportationLabels(t) as any)[transportation as string];
};

export const getTransportationVariantLabels = (
  t: any,
  transportation: Transportation
) => transportationVariantLabels(t)[transportation];

const transportationVariantInputTypes: {
  [K in Transportation]: "select" | "none" | "range";
} = {
  train: "select",
  bike: "select",
  walk: "none",
  plane: "select",
  travelBus: "none",
  ship: "none",
  car: "range",
  miniBus: "range",
  eCar: "range",
  motorcycle: "range",
  ferry: "select",
  "": "none" as NoTransportation,
};

export const getTransportationVariantInputType = (variant: Transportation) =>
  transportationVariantInputTypes[variant];

const transportationVariantRanges = {
  "": null,
  train: null,
  bike: null,
  walk: null,
  plane: null,
  travelBus: null,
  ship: null,
  car: { min: 1, max: 5 },
  eCar: { min: 1, max: 5 },
  miniBus: { min: 1, max: 13 },
  motorcycle: { min: 1, max: 2 },
  ferry: null,
};

export const getTransportationVariantRange = (transportation: Transportation) =>
  transportationVariantRanges[transportation] as {
    min: number;
    max: number;
  } | null;

export const GREEN_DARK = "#689439";
export const GREEN_LIGHT = "#b6cb82";

export const transportationColors: { [K in Transportation]: string } = {
  train: "#F0836C", // orange
  bike: GREEN_DARK, // dark green
  walk: GREEN_LIGHT, // light green
  plane: "#e6007e", // myclimate primary (magenta)
  travelBus: "#009BD9", // myclimate secondary (blue)
  ship: "#004F91", // dark blue
  car: "#906e5b", // light brown
  miniBus: "#634d3b", // dark brown
  eCar: "#f3d4b8", // very light brown
  motorcycle: "#84CFED", // light blue
  ferry: "#371F76", // purple
  "": "" as NoTransportation, // no color when anything isn't chosen.
};
