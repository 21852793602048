import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Leg } from "sharedTypes/modelTypes";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import LegFormFields from "./LegFormFields";
import LegsTable from "./LegsTable";
import Map from "../Map";
import StepButton from "../StepButton";
import MapLegsTable from "./MapLegsTable";

import client from "src/helpers/feathers";
import flyingDistanceBetweenCoords from "../utils/calculateFlyingDistance";
import calculateNavigatingDistance from "../utils/calculateNavigatingDistance";

export type TLegFormValues = Pick<
  Leg,
  | "name"
  | "distanceInKm"
  | "transportation"
  | "transportationVariant"
  | "origin"
  | "destination"
>;

type TTripCalculatorProps = {
  legs: Leg[];
  createLeg: (data: TLegFormValues, params?: any) => any;
  updateLeg: (id: number, data: Leg, params?: any) => any;
  removeLeg: (id: number, params?: any) => any;
  origin: string;
  destination: string;
  setShowAnalysis: Dispatch<SetStateAction<boolean>>;
  setShowMap: Dispatch<SetStateAction<boolean>>;
  showMap: boolean;
  showAnalysis: boolean;
};
const TripCalculator = ({
  legs,
  createLeg,
  updateLeg,
  removeLeg,
  setShowAnalysis,
  setShowMap,
  showMap,
  showAnalysis,
}: React.PropsWithChildren<TTripCalculatorProps>): React.ReactElement<
  any,
  any
> => {
  const [activeLegId, setActiveLegId] = useState<number | null>(null);
  const [showNewLegForm, setShowNewLegForm] = useState(false);
  const [isStepButtonDisabled, setStepButtonDisabled] = useState(false);
  // State variable to track whether any component is shown
  const [isComponentShown, setComponentShown] = useState(true); // state to decide if initial instructions on Trip Calculator are shown.
  const [isEditingLeg, setIsEditingLeg] = useState(false);
  const [haveAllLegsTransportation, setHaveAllLegsTransportation] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [legPolylines, setLegPolylines] = useState<google.maps.Polyline[]>([]);

  const [markerPositions, setMarkerPositions] = useState<string[]>([]);
  // Function to update the button state
  const updateStepButtonState = (
    isDisabled: boolean | ((prevState: boolean) => boolean)
  ) => {
    setStepButtonDisabled(isDisabled);
  };
  const { t } = useTranslation(["common", "tripViewSolo"]);

  const [stepClicked, setStepClicked] = useState(false);

  // component helper functions
  const toggleActiveLeg = (legId: number) => {
    if (legId === activeLegId) {
      setActiveLegId(null);
      setIsEditingLeg(false); // Reset the state when closing LegFormFields
    } else {
      setActiveLegId(legId);
      setIsEditingLeg(true); // Set the state when opening LegFormFields
    }
  };

  const handleEditTripButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setIsEditMode(true);
    setShowMap(true);
  };

  // component specific elements
  const CreateLegButton = () => (
    <Button
      variant="outline-secondary"
      onClick={(e) => {
        e.preventDefault();
        setShowNewLegForm(true);
        setComponentShown(false); // Hide the initial text when showing NewLegForm
      }}>
      {t("tripViewSolo:StreckeHinzufügenButton")}
    </Button>
  );

  const EditLegForm: React.FC<{ legToEdit: Leg }> = ({ legToEdit }) => {
    const closeEditForm = () => setActiveLegId(null);
    return (
      <LegFormFields
        onSubmit={(leg: TLegFormValues) => {
          // updateLeg returns promise, use it to set error messages or notifications upon return
          if (activeLegId) updateLeg(activeLegId, { ...legToEdit, ...leg });
          closeEditForm();
        }}
        // pass existing leg to use for the form state
        defaultValues={legToEdit}>
        <Row>
          <Col>
            <Button
              variant="dark"
              onClick={() => {
                if (activeLegId) removeLeg(activeLegId);
                closeEditForm();
              }}>
              {t("Löschen")}
            </Button>
          </Col>
          <Col>
            <Button variant="outline-secondary" onClick={closeEditForm}>
              {t("Abbrechen")}
            </Button>
          </Col>
          <Col>
            <Button variant="primary" type="submit">
              {t("Ändern")}
            </Button>
          </Col>
        </Row>
      </LegFormFields>
    );
  };

  const NewLegForm: React.FC = () => {
    const closeNewLegForm = () => {
      setShowNewLegForm(false);
    };

    const handleSubmit = (leg: TLegFormValues) => {
      try {
        // leg creation with a delay
        setTimeout(() => {
          createLeg(leg);

          // Set showAnalysis to true after leg creation
          setShowAnalysis(true);

          closeNewLegForm();
        }, 200);
      } catch (error) {
        // Handle errors if necessary
        console.error("Error creating leg:", error);
      }
    };

    return (
      <LegFormFields
        onSubmit={(leg: TLegFormValues) => handleSubmit(leg)}
        defaultValues={{
          distanceInKm: 0,
          transportation: "car",
          transportationVariant: "1",
          origin: "",
          destination: "",
        }}>
        <Row>
          <Col>
            <Button variant="outline-dark" onClick={closeNewLegForm}>
              {t("Abbrechen")}
            </Button>
          </Col>
          <Col>
            <Button variant="primary" type="submit">
              {t("Erstellen")}
            </Button>
          </Col>
        </Row>
      </LegFormFields>
    );
  };

  const handleDistanceRequest = (
    leg: Leg,
    mode: string,
    transitMode?: string
  ): Promise<any> => {
    const data = {
      origin: leg.origin,
      destination: leg.destination,
      mode,
      transitMode,
    };

    return client
      .service("api/distance-matrix")
      .create(data)
      .then((response: any) => {
        const newDistance = parseInt(response.split(" ")[0].replace(",", ""));
        updateLeg(leg.id, { ...leg, distanceInKm: newDistance });
        return response;
      })
      .catch((error: any) => {
        console.error("Error sending data to the server:", error);
        throw error; // Re-throw the error to handle it in the calling code
      });
  };

  const calculateFlyingDistance = (leg: Leg): number => {
    const newDistance = flyingDistanceBetweenCoords(
      leg.origin,
      leg.destination
    );
    updateLeg(leg.id, { ...leg, distanceInKm: newDistance });
    return newDistance;
  };

  const calculateSailingDistance = (leg: Leg): number => {
    const newDistance = calculateNavigatingDistance(
      leg.origin,
      leg.destination
    );
    updateLeg(leg.id, { ...leg, distanceInKm: newDistance });
    return newDistance;
  };

  const noDistanceCalculation = (leg: Leg): number => {
    const newDistance = 0;
    updateLeg(leg.id, { ...leg, distanceInKm: newDistance });
    return newDistance;
  };

  const toggleMap = () => {
    setShowMap(!showMap);
    setShowAnalysis(false);
    setComponentShown(false); // Hide the initial text when showing Map
    setStepClicked(false); // reset state to false
  };

  const handleFinishClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setStepClicked(true);
    setIsEditMode(false);
    setShowAnalysis(false); // so the analysis is not shown until the button is clicked
    setComponentShown(false); // Hide the initial text when showing MapLegsTable
  };

  const handleAnalyzeTravelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowMap(false);
    setShowAnalysis(true); // Show the Analysis component when "Reise Analysieren" is clicked
  };

  const tripCalculatorComponentText = [
    <p
      key="tripCalculatorText"
      dangerouslySetInnerHTML={{
        __html: t("tripViewSolo:Text"),
      }}
    />,
  ];

  return (
    <>
      {isComponentShown && !isEditingLeg && tripCalculatorComponentText}
      <Row className="justify-content-lg-center mt-4">
        {/* fill row when on mobile, auto size center when on a bigger screen */}
        <Col>
          {showMap ? (
            !stepClicked || isEditMode ? (
              <>
                <Map
                  legs={legs}
                  createLeg={createLeg}
                  removeLeg={removeLeg}
                  updateLeg={updateLeg}
                  updateStepButtonState={updateStepButtonState}
                  legPolylines={legPolylines}
                  setLegPolylines={setLegPolylines}
                  markerPositions={markerPositions}
                  setMarkerPositions={setMarkerPositions}
                />
                <StepButton
                  onStepClick={handleFinishClick}
                  isDisabled={isStepButtonDisabled}
                  buttonText={t("common:Fertig")}
                />
              </>
            ) : (
              legs &&
              (!showAnalysis ? (
                <>
                  <MapLegsTable
                    legs={legs}
                    handleDistanceRequest={handleDistanceRequest}
                    calculateFlyingDistance={calculateFlyingDistance}
                    calculateSailingDistance={calculateSailingDistance}
                    noDistanceCalculation={noDistanceCalculation}
                    activeLegId={activeLegId}
                    onRowClick={toggleActiveLeg}
                    setHaveAllLegsTransportation={setHaveAllLegsTransportation}
                    handleEditTripButtonClick={handleEditTripButtonClick}
                  />
                  <StepButton
                    onStepClick={handleAnalyzeTravelClick}
                    isDisabled={!haveAllLegsTransportation}
                    buttonText={t("common:ReiseAnalysieren")}
                  />
                </>
              ) : (
                <LegsTable
                  legs={legs}
                  activeLegId={activeLegId}
                  onRowClick={toggleActiveLeg}
                />
              ))
            )
          ) : (
            legs.length > 0 && (
              <LegsTable
                legs={legs}
                activeLegId={activeLegId}
                onRowClick={toggleActiveLeg}
              />
            )
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {!showMap && !showNewLegForm && (
            <Button variant="primary" onClick={toggleMap}>
              {t("tripViewSolo:KarteButton")}
            </Button>
          )}
          {activeLegId === null && !showNewLegForm && !showMap && (
            <CreateLegButton></CreateLegButton>
          )}
          {activeLegId === null && showNewLegForm && !showMap && (
            <NewLegForm></NewLegForm>
          )}
          {activeLegId && !showMap && (
            <EditLegForm
              legToEdit={
                legs.find((leg) => leg.id === activeLegId)!
              }></EditLegForm>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TripCalculator;
