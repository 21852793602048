import React from "react";
import { useTranslation } from "react-i18next";
import { Transportation } from "sharedTypes/modelTypes";
import { getTransportationVariantLabels } from "src/helpers/transportation";

// returns the variants as <option> elements for a given transportation
const TransportationVariantSelectOptions: React.FC<{
  transportation: Transportation;
}> = ({ transportation }) => {
  const { t } = useTranslation("common");

  const labels = getTransportationVariantLabels(t, transportation);
  return (
    <>
      {Object.keys(labels).map((key) => (
        <option key={key} value={key}>
          {labels[key as keyof typeof labels]}
        </option>
      ))}
    </>
  );
};

export default TransportationVariantSelectOptions;
