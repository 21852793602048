import React from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import { getEmissionFactor } from "src/helpers/data";
import { transportationColors } from "src/helpers/transportation";
import { Transportation } from "sharedTypes/modelTypes";

const TransportBubbleChart: React.FC = () => {
  const { t } = useTranslation("common");

  const transports: {
    transport: Transportation;
    variant: string | number | null;
    label: string;
  }[] = [
    { transport: "train", variant: "switzerland", label: `${t("Zug")} (CH)` },
    { transport: "train", variant: "germany", label: `${t("Zug")} (DE)` },
    { transport: "bike", variant: "bike", label: t("Fahrrad") },
    { transport: "bike", variant: "eBike", label: t("E-Bike") },
    {
      transport: "plane",
      variant: "shortDistance",
      label: t("FlugInnerhalbEuropa"),
    },
    { transport: "travelBus", variant: null, label: t("Reisebus") },
    { transport: "miniBus", variant: 1, label: `${t("Minibus")} (1 P.)` },
    { transport: "miniBus", variant: 13, label: `${t("Minibus")} (13 P.)` },
    { transport: "ship", variant: null, label: t("Schiff") },
    { transport: "car", variant: 1, label: `${t("Auto")} (1 P.)` },
    { transport: "car", variant: 5, label: `${t("Auto")} (5 P.)` },
    { transport: "motorcycle", variant: 1, label: `${t("Motorrad")} (1 P.)` },
    { transport: "motorcycle", variant: 2, label: `${t("Motorrad")} (2 P.)` },
    { transport: "eCar", variant: 1, label: `${t("Elektroauto")} (1 P.)` },
  ];

  const children = transports.map(({ transport, variant, label }) => ({
    id: transport + (variant || ""),
    value: getEmissionFactor(transport, variant),
    color: transportationColors[transport],
    label: label,
  }));
  type RawDatum = {
    id: string;
    value: number;
    color: string;
    label: string;
    children?: RawDatum[];
  };
  const data: RawDatum = {
    id: "parent",
    value: 0,
    color: "red",
    label: "",
    children: children,
  };

  return (
    <ResponsiveCirclePacking
      data={data}
      colors={(node) => node.data.color || "#5BC5F2"}
      childColor={{ from: "color" }}
      padding={4}
      leavesOnly={true}
      enableLabels={true}
      label={(node) => node.data.label}
      labelTextColor="white"
      labelsSkipRadius={35}
      tooltip={({ data: { label, value } }) => {
        return (
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "3px",
              padding: "0.4rem",
            }}>
            <b>{label}</b>
            <br />
            {value.toFixed(3)} kg CO<sub>2</sub> / Pkm
          </div>
        );
      }}
      animate={false}
    />
  );
};

export default TransportBubbleChart;
