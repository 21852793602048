import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column } from "react-table";
import { Table } from "react-bootstrap";
import { Leg, Transportation } from "sharedTypes/modelTypes";

import { addEmissions, totalEmissions } from "src/helpers/data";
import { getTransportationLabel } from "src/helpers/transportation";

type TLegsTableProps = {
  legs: Leg[];
  activeLegId: number | null;
  onRowClick: (id: number) => void;
};
type UnpackArrayType<T> = T extends (infer R)[] ? R : never;
const LegsTable = ({
  legs,
  activeLegId,
  onRowClick,
}: React.PropsWithChildren<TLegsTableProps>): React.ReactElement<any, any> => {
  // calculate emissions
  const { t } = useTranslation("common");

  const data = useMemo(() => addEmissions(legs), [legs]);

  const total = totalEmissions(legs);
  // the following two useMemo's are needed because of react-table's sorting implementation.
  // see https://react-table.tanstack.com/docs/api/useTable
  const columns: Array<Column<UnpackArrayType<typeof data>>> = useMemo(
    () => [
      {
        Header: t("Teilstrecke"),
        accessor: (row, _ix) => row.leg.name,
      },
      {
        Header: t("Distanz"),
        accessor: (row, _ix) => row.leg.distanceInKm,
      },
      {
        Header: t("Transport"),
        accessor: (row, _ix) => row.leg.transportation,
        Cell: ({ value }: { value: Transportation }) =>
          getTransportationLabel(t, value),
      },
      {
        Header: () => (
          <span dangerouslySetInnerHTML={{ __html: t("CO2Person") }} />
        ),
        accessor: "emissions_in_kgCO2",
        Cell: ({ value }) => value.toFixed(3),
      },
    ],
    [t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<UnpackArrayType<typeof data>>({ columns, data }, useSortBy);
  return (
    <Table responsive striped hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                {/* sort direction indicator */}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const id = row.original.leg.id;
          return (
            <tr
              onClick={(e) => {
                e.preventDefault();
                onRowClick(id);
              }}
              className={id === activeLegId ? "bg-primary" : ""}
              {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th
            style={{ textAlign: "right" }}
            colSpan={4}
            dangerouslySetInnerHTML={{
              __html: `${t("TotalCO2Emissionen")}: ${total.toFixed(3)} kg`,
            }}
          />
        </tr>
      </tfoot>
    </Table>
  );
};

export default LegsTable;
