import { useState } from "react";
import { Leg } from "sharedTypes/modelTypes";
import AnalysisComparison from "./AnalysisComparison";
import AnalysisTrip from "./AnalysisTrip";
import Tabs from "./Tabs";

export type DisplayType = "trip" | "comparison";

type TAnalysisProps = {
  legs: Leg[];
  defaultWaffleSizeKg?: number;
};
const Analysis: React.FC<TAnalysisProps> = ({ legs, defaultWaffleSizeKg }) => {
  const [display, setDisplay] = useState("trip" as DisplayType);

  const AnalysisSwitch = () => {
    switch (display) {
      case "trip":
        return (
          <AnalysisTrip legs={legs} defaultWaffleSizeKg={defaultWaffleSizeKg} />
        );

      case "comparison":
        return <AnalysisComparison legs={legs} />;

      default:
        return null;
    }
  };

  if (!legs) return null;

  return (
    <div>
      <Tabs selected={display} onSelected={(tab) => setDisplay(tab)} />
      <AnalysisSwitch />
    </div>
  );
};

export default Analysis;
