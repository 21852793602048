import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { extractNearestPlace } from "./utils/extractNearestPlace";
import { TLegFormValues } from "./trip/TripCalculator";
import { Leg } from "sharedTypes/modelTypes";

const Map: React.FC<{
  createLeg: (data: TLegFormValues, params?: any) => any;
  removeLeg: (id: number, leg: Leg) => any;
  updateLeg: (id: number, leg: Leg) => any;
  legs: Leg[];
  updateStepButtonState: React.Dispatch<React.SetStateAction<boolean>>;
  legPolylines: google.maps.Polyline[];
  setLegPolylines: React.Dispatch<React.SetStateAction<google.maps.Polyline[]>>;
  markerPositions: string[];
  setMarkerPositions: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({
  createLeg,
  removeLeg,
  updateLeg,
  legs,
  updateStepButtonState,
  legPolylines,
  setLegPolylines,
  markerPositions,
  setMarkerPositions,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
    language: "de",
  });

  const [map, setMap] = useState<google.maps.Map | null>(null); // Define map state

  const countriesData = [
    { name: "Germany", lat: 48.210033, lng: 11.760983 },
    { name: "France", lat: 46.227638, lng: 2.213749 },
    { name: "Switzerland", lat: 46.818188, lng: 8.227512 },
    { name: "Austria", lat: 47.516231, lng: 14.550072 },
    { name: "Italy", lat: 41.902782, lng: 12.496366 },
  ];

  const averageLat =
    countriesData.reduce((sum, country) => sum + country.lat, 0) /
    countriesData.length;
  const averageLng =
    countriesData.reduce((sum, country) => sum + country.lng, 0) /
    countriesData.length;

  const [center, setCenter] = useState({ lat: averageLat, lng: averageLng }); // Initial center
  const [polyline] = useState<google.maps.Polyline | null>(null); // Define polyline state

  const mapStyles = {
    height: "80vh",
    width: "100%",
  };

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const latString = e.latLng.lat().toString();
      const lngString = e.latLng.lng().toString();
      const position = `${latString},${lngString}`; // position of interpolated strings
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      const coordinates = { lat, lng }; // position but with actual coordenates not strings

      // Check if the clicked position is outside the initial center
      if (coordinates.lat !== center.lat || coordinates.lng !== center.lng) {
        // Update the center to the clicked coordinates
        setCenter(coordinates);
      }

      handleMarkerClick(position);
    }
  };

  // Common function to handle leg creation, geocoding, and marker addition
  const handleMarkerClick = async (position: string) => {
    const lastMarkerPosition =
      markerPositions.length > 0
        ? markerPositions[markerPositions.length - 1]
        : null;

    if (lastMarkerPosition) {
      const [lastLatStr, lastLngStr] = lastMarkerPosition.split(",");
      const lastLat = parseFloat(lastLatStr);
      const lastLng = parseFloat(lastLngStr);

      const [latString, lngString] = position.split(",");
      const lat = parseFloat(latString);
      const lng = parseFloat(lngString);

      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(lat, lng);

      const results = await new Promise<google.maps.GeocoderResult[] | null>(
        (resolve) => {
          geocoder.geocode({ location: latLng }, (res, status) => {
            if (status === "OK") {
              resolve(res);
            } else {
              console.error(
                "Geocode was not successful for the following reason:",
                status
              );
              resolve(null);
            }
          });
        }
      );

      if (!results || !results[0]) {
        return;
      }

      const origin = `${lastLat},${lastLng}`;
      const destination = `${latString},${lngString}`;

      const lastLatLng = new window.google.maps.LatLng(lastLat, lastLng);

      const lastNearestPlace = await extractNearestPlace(geocoder, lastLatLng);
      const nearestPlace = await extractNearestPlace(geocoder, latLng);

      const legName = `${lastNearestPlace} - ${nearestPlace}`;

      // Create a leg
      createLeg({
        name: legName,
        distanceInKm: 0,
        transportation: "",
        transportationVariant: "",
        origin,
        destination,
      });

      // Add the new marker position
      setMarkerPositions((prevPositions) => [...prevPositions, position]);
    } else {
      // If there are no markers, simply add the marker position
      setMarkerPositions([position]);
    }
  };

  const removeMarker = (position: string) => {
    // Find the index of the removed marker
    const removedMarkerIndex = markerPositions.findIndex(
      (marker) => marker === position
    );

    // Handle the case when the marker is in the middle
    if (
      removedMarkerIndex > 0 &&
      removedMarkerIndex < markerPositions.length - 1
    ) {
      const prevLeg = legs.find((leg) =>
        leg.destination.includes(markerPositions[removedMarkerIndex])
      );
      const nextLeg = legs.find((leg) =>
        leg.origin.includes(markerPositions[removedMarkerIndex])
      );

      if (prevLeg && nextLeg) {
        const newName = `${prevLeg.name.split(" - ")[0]} - ${
          nextLeg.name.split(" - ")[1]
        }`;

        const newOrigin = prevLeg.origin;

        const newDestination = nextLeg.destination;

        updateLeg(prevLeg.id, {
          ...prevLeg,
          name: newName,
          origin: newOrigin,
          destination: newDestination,
        });

        // remove the other Leg that contains the removed marker
        removeLeg(nextLeg.id, nextLeg);
      }
    } else {
      // Find the legs associated with the removed marker
      const legsToRemove = legs.filter(
        (leg) =>
          leg.origin.includes(position) || leg.destination.includes(position)
      );

      // Remove the found legs
      legsToRemove.forEach((leg) => {
        removeLeg(leg.id, leg);
      });
    }

    // Update the markers excluding the removed one
    const updatedMarkers = markerPositions.filter(
      (marker) => marker !== position
    );
    setMarkerPositions(updatedMarkers);

    // If a polyline exists, update its path by excluding the removed marker
    if (polyline) {
      const path = polyline.getPath().getArray();
      const updatedPath = path.filter((coord) => {
        const coordString = `${coord.lat()},${coord.lng()}`;
        return updatedMarkers.includes(coordString);
      });

      // Set the new path for the polyline
      polyline.setPath(updatedPath);
    }
  };

  const evaluateMarkerRemoval = async (position: string) => {
    // Remove the marker
    removeMarker(position);
  };

  useEffect(() => {
    // Cleanup function to remove previous leg polylines when the markers change
    legPolylines.forEach((polyline) => polyline.setMap(null));

    if (map && markerPositions.length >= 2) {
      // Create a new polyline for each leg
      const newLegPolylines = markerPositions
        .slice(0, -1)
        .map((position, index) => {
          const [latStr, lngStr] = position.split(",");
          const lat = parseFloat(latStr);
          const lng = parseFloat(lngStr);

          const nextPosition = markerPositions[index + 1];
          const [nextLatStr, nextLngStr] = nextPosition.split(",");
          const nextLat = parseFloat(nextLatStr);
          const nextLng = parseFloat(nextLngStr);

          const path = [
            { lat, lng },
            { lat: nextLat, lng: nextLng },
          ];

          return new window.google.maps.Polyline({
            path,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map,
          });
        });

      // Add the new leg polylines to the legPolylines state
      setLegPolylines(newLegPolylines);
    } else {
      // Clear all leg polylines
      setLegPolylines([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markerPositions]);

  const { t } = useTranslation("tripViewSolo");

  return (
    <>
      <p>{t("KarteIntro")}</p>
      {isLoaded && (
        <GoogleMap
          data-testid="map-container"
          mapContainerStyle={mapStyles}
          zoom={5}
          center={center}
          onClick={onMapClick}
          onLoad={(map: any) => setMap(map)} // Set the map variable
        >
          {markerPositions.map((position, index) => (
            <div className="custom-marker" key={index}>
              <Marker
                data-testid="marker"
                position={{
                  lat: parseFloat(position.split(",")[0]),
                  lng: parseFloat(position.split(",")[1]),
                }}
                onClick={() => evaluateMarkerRemoval(position)}
              />
            </div>
          ))}
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
