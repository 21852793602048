import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { useForm, UseFormRegister } from "react-hook-form";

import client from "src/helpers/feathers";

import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Alert } from "react-bootstrap";
import { TLogin } from "src/@types";
//import userEvent from "@testing-library/user-event";
import { User, Userpin } from "sharedTypes/modelTypes";

type TFormValues = {
  email: string;
  inviteCode: string;
  pin: string;
};
type TStudentPinForm = {
  register: UseFormRegister<TFormValues>;
  wrongPin: boolean;
};
const StudentPinForm: React.FC<TStudentPinForm> = ({ register, wrongPin }) => {
  const { t } = useTranslation("Loginseite");
  return (
    <>
      <Form.Row>
        <Col>
          <Alert variant="info">
            <div dangerouslySetInnerHTML={{ __html: t("DoppelterName") }} />
          </Alert>
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formPin">
          <Form.Label>PIN</Form.Label>
          <Form.Control
            {...register("pin", { required: true })}
            type="number"
            isInvalid={wrongPin}
          />
          {wrongPin && (
            <Form.Control.Feedback type="invalid">
              {t("common:FalscherPIN")}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form.Row>
    </>
  );
};

type TClassJoinViewProps = {
  setLogin: React.Dispatch<React.SetStateAction<TLogin | null>>;
};
const ClassJoinView: React.FC<TClassJoinViewProps> = ({ setLogin }) => {
  // parse /classes/join?q=1Nv8C0d3
  const params = new URLSearchParams(useLocation().search);
  const inviteCode = params.get("q");
  const { t } = useTranslation("common");

  const history = useHistory();

  const { register, watch, handleSubmit, formState } = useForm<TFormValues>({
    defaultValues: { inviteCode: inviteCode ? inviteCode : "" },
  });

  const watchInviteCode = watch("inviteCode");

  // local state
  const [studentExists, setStudentExists] = useState(false);
  const [wrongPin, setWrongPin] = useState(false);

  const onSubmit = ({ email, inviteCode, pin }: TFormValues) => {
    if (pin) {
      client
        .authenticate({
          strategy: "local",
          email: `${email}@${inviteCode}`,
          password: pin,
        })
        .then((result) => {
          setLogin(result as TLogin);
          history.push(`/trip`);
        })
        .catch((error) => {
          switch (error.name) {
            case "NotAuthenticated":
              setWrongPin(true);
              break;
            default:
              console.error(error);
          }
        });
    } else {
      client
        // create the user
        .service("api/users")
        .create({ email }, { query: { inviteCode } })
        .then(({ user, userpin }: { user: User; userpin: Userpin }) => {
          client
            // try to login
            .authenticate({
              strategy: "local",
              email: user.email,
              password: userpin.pin,
            })
            // set local login state
            .then((result) => {
              setLogin(result as TLogin);
              user.classId && history.push(`/trip`);
            })
            .catch(console.error);
        })
        // .then(({ email, class_id, userpin: { pin: password } }:
        //       {email: string, class_id: number, userpin: { pin: string }}) =>
        //   client
        //     // try to login
        //     .authenticate({
        //       strategy: "local",
        //       email,
        //       password: password.toString(),
        //     })
        //     // set local login state
        //     .then((result) => {
        //       setLogin(result as TLogin);
        //       class_id && history.push(`/trip`);
        //     })
        //     .catch(console.error)
        // )
        .catch((error: any) => {
          switch (error.message) {
            case "email_exists":
              setStudentExists(true);
              break;
            default:
              console.error(error);
          }
        });
    }
  };

  return (
    <>
      <h2>{t("Klasse beitreten Titel")}</h2>
      <p>
        {t("KlasseIntro")} <strong>{watchInviteCode}</strong>{" "}
        {t("KlasseIntro2")}
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="formInviteCode">
            <Form.Label>{t("Einladungscode")}</Form.Label>
            <Form.Control
              {...register("inviteCode", { required: true })}
              type="text"
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formUsername">
            <Form.Label>{t("Name")}</Form.Label>
            <InputGroup>
              <FormControl
                {...register("email", { required: true })}
                type="text"
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        {studentExists && (
          <StudentPinForm register={register} wrongPin={wrongPin} />
        )}

        <Button
          variant="primary"
          type="submit"
          disabled={formState.isSubmitting}>
          {t("Startseite:KlasseBeitretenButton")}
        </Button>
      </Form>
    </>
  );
};

export default ClassJoinView;
