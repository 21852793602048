import io from "socket.io-client";
import feathers from "@feathersjs/client";
import auth from '@feathersjs/authentication-client'

const socket = io(
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://localhost:3030" : "",
  {
    transports: ["websocket"],
    forceNew: true,
  }
);
const client = feathers();

client.configure(feathers.socketio(socket));
// client.configure(
//   feathers.authentication({
//     storage: window.localStorage,
//   })
// );
client.configure(auth({
  path: '/api/authentication'
}))

export default client;
