import { TLogin } from "src/@types";

type TZettelNavigationProps = {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  login: TLogin | null;
};
const ZettelNavigation: React.FC<TZettelNavigationProps> = ({
  menuOpen,
  setMenuOpen,
  login,
}) => {
  if (menuOpen) {
    return (
      <button
        id="closeMenu"
        type="button"
        className="close nav-link"
        data-toggle="collapse"
        data-target="#navbarMenu"
        aria-controls="navbarMenu"
        aria-expanded="true"
        aria-label="Close"
        onClick={() => setMenuOpen(false)}>
        <span aria-hidden="true"></span>
      </button>
    );
  } else {
    return (
      <li className="nav-item">
        <button
          onClick={() => setMenuOpen(true)}
          style={{
            border: "none",
            background: "transparent",
            outline: "none",
          }}>
          <span title="Menü" className="icn-hamburger-navi"></span>
        </button>
      </li>
    );
  }
};

export default ZettelNavigation;
